import React, { useEffect, useMemo, useState, useContext, useRef, useCallback } from "react";

import { json, Link, useFetcher, useParams } from "react-router-dom";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppPages from "./AppPages";
import AppUsers from "./AppUsers";
import AppSettings from "./AppSettings";
import AppContents from "./AppContents";
import AppRoles from "./AppRoles";
import { setBackground } from "../../../../service/background";
import Header1 from "../../../../components/Header1";
import "../../../../App.css";
import { MfctContext } from "../../../../context";
import AppBuild from "./AppBuild";
import AppTemplates from "./AppTemplates";
import AppSchemas from "./AppSchemas";
import { getAllRecords } from "../../../../service/localdata";
import AppNewIds from "./AppNewIds";
import AppNavigation from "./AppNavigation";
import AppActions from "./AppActions";
import { createDeployment, installDeployment } from "../../../../tools/deployment";
import SelectDropDown from "../../../../components/inputs/SelectDropDown";

function Apps() {
  const { app_id, what ,sub_what} = useParams();
  console.log("App What", { app_id, what });
  const [apps, setApps] = useState([]);
  const [show, setShow] = useState(
    app_id ? { app: parseInt(app_id), what } : null
  );

  const [tabs, setTabs] = useState([
    { what: "Pages", text: "Pages", icon: fa.faBookOpen },

    { what: "Contents", text: "Contenus", icon: fa.faPager },
    { what: "Schemas", text: "Schémas", icon: fa.faPager },
    { what: "Templates", text: "Templates", icon: fa.faPager },
    { what: "Actions", text: "Actions", icon: fa.faPlayCircle },
    { what: "Navigation", text: "Navigation", icon: fa.faLink },
  ]);

  const classTypes = {
    error: "text-red-500 font-bold",
    warning: "text-yellow-500",
  };
  const [tabsSide, setTabsSide] = useState([
    { what: "Links", text: "Links", icon: fa.faLink },
    { what: "Settings", text: "Paramètres", icon: fa.faGear },
    { what: "Build", text: "Build", icon: fa.faGears,Link : (app)=>`/deployments?app_id=${app.app_id}` },
    // { what: "CreateNewIds", text: "CreateNewIds", icon: fa.faGears },
    { what: "Users", text: "Utilisateurs", icon: fa.faUsers },
    { what: "Roles", text: "Rôles", icon: fa.faLock },
  ]);
  const { project, getProject, setAuthUser, authUser, getGlobalData } =
    useContext(MfctContext);

  const getApps = async (unselect) => {
    getGlobalData();
    if (unselect) setShow();
    // var _apps = await project.api("/get_myapps", { body: {} });

    var _apps = await getAllRecords("app");
    _apps.sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1));
    console.log(_apps);
    setApps(
      _apps.map((app) => {
        return {
          ...app,
          isModifiable:
            (process.env.REACT_APP_IS_TEST === "true") === (app.is_test === 1),
        };
      })
    );
  };

  const share = async (app) => {
    if (navigator.share) {
      try {
        await navigator
          .share({
            url: "https://" + app.domain + ".appisyou.com",
            title: app.name,
            text: "Installe cette appli c'est de la bombe !",
          })
          .then(
            () => 1
            //alert("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        //alert(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      // alert(  "Web share is currently not supported on this browser. Please provide a callback");
    }
  };

  const [deployments,setDeployments ]=useState([])
  const getDeployments=   async ()=>{
    const _result = await project.api("/get_deployments", { body: {status:"ACTIF"} });
  setDeployments(_result.filter(d=>true || d.status==="ACTIF" ))
  }

  const appDeployments=   useCallback( (app)=>{
    return deployments.filter(d=>   d.app_id===app.app_id    && ( project.isDev || d.server_account!=="LOCALHOST"))
      
  },[project,deployments])



  useEffect(() => {
    getDeployments()
    getApps();
  }, [project]);

  useEffect(() => {
    if (show?.app) {
      document.getElementById("searchBar").value = "";
      setSearchValue("");
    }
    document.title = show
      ? show?.what +
        " - " +
        apps.find((a) => a.app_id + "" === show.app + "")?.name
      : "Applications";
  }, [show]);

  const searchBar = useRef();
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="safearea text-white">
      <Header1
        title={"Applications"}
        linkLeft={"/"}
        linkRight={"/app/add/60"}
        iconRight={fa.faPlus}
        classRight={"p-2 rounded-full border border-white "}
      >
        <input
          type="text"
          id={"searchBar"}
          ref={searchBar}
          onChange={(e) => setSearchValue(e.target.value)}
          className="w-full mx-4 rounded-3xl text-zinc-700 p-2 px-4"
        />
      </Header1>

      <div className="w-full flex flex-col p-4">
        {apps &&
          apps
            .filter(
              (app) =>
                (!show || show.app === app.app_id) &&
                (show?.app ||
                  !searchValue ||
                  searchValue === "" ||
                  app.name.toLowerCase().indexOf(searchValue.toLowerCase()) >
                    -1)
            )
            .map((app, i) => (
              <div className="flex flex-col w-full" key={i}>
                <div className="rounded-xl border-t border-zinc-500 w-full  text-black flex flex-col items-start p-4 my-2 bg-white">
                <div className="mx-4 text-2xl font-extrabold text-left w-full md:hidden">
                          {" "}
                          {app.name}
                        </div>
                  <div className="flex flex-row   w-full  justify-center items-start">
                    <img
                      src={
                        app.profile.Logo ?? 
                        process.env.REACT_APP_MFTC_SERVER_URL_FILES +
                        `profiles/${app.domain}/user.png`
                      }
                      className=" m-1 w-28 h-28 rounded-sm"
                      alt="logo"
                    />
                    <div className=" flex flex-col grow ">
                      <div className="flex flex-row w-full justify-end">
                        <span className="mx-4 text-2xl font-extrabold text-left grow hidden md:inline-block">
                          {" "}
                          {app.name}
                        </span>
                        <img
                      src={
                     
                        process.env.REACT_APP_MFTC_SERVER_URL +
                        `appisyou/get_qrcode/${app.app_id}`
                      }
                      className=" m-1 w-28 h-28 rounded-sm"
                      alt="logo"
                    />
                        <div className="flex flex-col items-center justify-center border-l border-zinc-200 pl-2">
                          {" "}
                          <a
                            href={
                              project.isDev
                                ? `https://localhost:3030/?domain=${app.domain}`
                                : `https://${app.domain}.appisyou.com/`
                            }
                            target="_blank"
                            className="mx-0 hidden"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon
                              icon={fa.faLink}
                              className=" rounded-full border border-white  "
                            ></FontAwesomeIcon>
                          </a>
                          <div className="hidden" onClick={() => share(app)}>
                            <FontAwesomeIcon
                              icon={fa.faShare}
                              className=" rounded-full border border-white  "
                            ></FontAwesomeIcon>
                          </div>
                          {tabsSide.map((tab, i) => (
                            <Link
                              to={
                                tab.Link ?  tab.Link(app):(
                                  app.app_id === show?.app && show?.what === tab.what
                                    ? "/apps"
                                    : `/apps/${app.app_id}/${tab.what}`)



                         
                              }
                              key={i}
                            >
                              {" "}
                              <div
                                key={i}
                                onClick={() =>
                                  setShow(
                                    app.app_id === show?.app &&
                                      show?.what === tab.what
                                      ? null
                                      : { app: app.app_id, what: tab.what }
                                  )
                                }
                                className={
                                  "appbutton p-1 rounded-md " +
                                  (app.app_id === show?.app &&
                                  show?.what === tab.what
                                    ? " bg-zinc-200 "
                                    : "")
                                }
                              >
                                <FontAwesomeIcon
                                  icon={tab.icon}
                                  className=" rounded-full border border-white  "
                                ></FontAwesomeIcon>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full text-sm flex flex-row items-center justify-center">
                    {tabs.map((tab, i) => (
                      <Link
                        to={ tab.Link ?  tab.Link(app):(
                          app.app_id === show?.app && show?.what === tab.what
                            ? "/apps"
                            : `/apps/${app.app_id}/${tab.what}`)
                        }
                        key={i}
                      >
                        {" "}
                        <div
                          key={i}
                          onClick={() =>
                            setShow(
                              app.app_id === show?.app &&
                                show?.what === tab.what
                                ? null
                                : { app: app.app_id, what: tab.what }
                            )
                          }
                          className={
                            " appbutton p-1 rounded-md " +
                            (app.app_id === show?.app && show?.what === tab.what
                              ? " bg-zinc-200 "
                              : "")
                          }
                        >
                          <FontAwesomeIcon
                            icon={tab.icon}
                            className=" rounded-full border border-white  "
                          ></FontAwesomeIcon>
                          <div className="text-xs md:text-sm">{tab.text}</div>
                        </div>
                      </Link>
                    ))}
                    {false && (
                      <div className="flex flex-row p-1 m-1">
                        {" "}
                        <Link to={`/app/${app.app_id}/contents`}>
                          Contenus
                        </Link>{" "}
                      </div>
                    )}
                  </div>
                </div>
{



show?.what === "Links" && show?.app === app.app_id && 


<div className="rounded-xl border-t border-zinc-500 w-full  text-black flex flex-col items-start p-4 my-2 bg-white">
  Déploiements
  {

  appDeployments(app).map(dep=> 
  <div key={dep.deploiement_id}    className="w-full border-t my-2 py-2 flex flex-col "    >
  <div className={"w-full flex justify-between"}>     {dep.server_account} <div className={"appbutton p-2 rounded-full  text-xs " + (   dep.status==="ACTIF" ?"bg-green-200": "bg-blue-200" )}> {dep.nature} - {dep.status}</div>   </div>
    { [...JSON.parse( dep.addresses?? '[]'),

...( dep.server_account==="LOCALHOST" &&  project.isDev ? [`localhost:3030/?domain=${app.domain}`] : []  )

    ].map(url=>                       <a key={url}
    href={    dep.status==="ACTIF" ?    `https://${url}` :`#` }
    target="_blank"
    className= { "mx-0 " +(   dep.status==="ACTIF" ?    `` :`line-through`)}
    rel="noreferrer"
  >
    <FontAwesomeIcon
      icon={fa.faLink}
      className=" rounded-full border border-white  "
    ></FontAwesomeIcon>
    {url}
  </a>) } 
  <div className="w-full flex justify-end my-2 gap-3" > 
    
    {    dep.owner_id === authUser.user_id && dep.server_account!=="LOCALHOST" &&
    <div className={ "appbutton p-2 rounded-full text-white text-xs " + (
      dep.owner_id !== authUser.user_id ?  "bg-gray-300  opacity-30" : "bg-blue-800"
    )}
  
     onClick={  ()=>{} }> Changer de propriétaire :todo</div>   

    }
       <div className={ "appbutton p-2 rounded-full text-white text-xs " + (
    dep.owner_id !== authUser.user_id ?  "bg-gray-300  opacity-30" : "bg-blue-700"
  )}

   onClick={ dep.owner_id !== authUser.user_id ? ()=>{} : ()=>installDeployment( dep,false,true,{setDeployments})}> Mettre à jour</div>     </div>


  
  
   
{dep.Result && dep.Result.length > 0 && (
                      <div
                        className="flex flex-col w-full border border-slate-500 rounded-xl  overflow-y-auto p-2"
                        style={{ maxHeight: "50vh" }}
                        id={"Result_" + dep.deployment_id}
                      >
                        Résultat :
                        {dep.Result.map((message, i) => (
                          <div
                            id={
                              "Result_" +
                              dep.deployment_id +
                              "_" +
                              (i === dep.Result.length - 1 ? "last" : i)
                            }
                            className={
                              "w-full p-1 text-xs text-left " +
                              (message.type && classTypes[message.type])
                            }
                            key={i}
                          >
                            {message.text}
                          </div>
                        ))}
                      </div>
                    )}
   </div>     ) }
   {  appDeployments(app).length===0 && 
   
  <div className="w-full flex justify-between border-t my-2 py-2" > Aucun déploiement   <div className="appbutton p-2 rounded-full bg-blue-700 text-white text-xs" 
  onClick={()=>createDeployment( {app_id, addresses:`["${app.domain}.appisyou.com"]`},{setDeployments},false)}> Déployer</div>     </div>

   }
   
                    </div>
}
                {show?.what === "Pages" && show?.app === app.app_id && (
                  <AppPages app={app} searchValue={searchValue} />
                )}
                {show?.what === "Users" && show?.app === app.app_id && (
                  <AppUsers app={app} searchValue={searchValue} />
                )}
                {show?.what === "Roles" && show?.app === app.app_id && (
                  <AppRoles app={app} searchValue={searchValue} />
                )}
                {show?.what === "Settings" && show?.app === app.app_id && (
                  <AppSettings getApps={getApps} app={app} />
                )}
                    {show?.what === "Navigation" && show?.app === app.app_id && (
                  <AppNavigation getApps={getApps} app={app} />
                )}
                {show?.what === "Actions" && show?.app === app.app_id && (
                  <AppActions getApps={getApps} app={app} />
                )}
                {show?.what === "Contents" && show?.app === app.app_id && (
                  <AppContents app={app} searchValue={searchValue}     sub_what={sub_what} />
                )}
                {
               // show?.what === "Build" && show?.app === app.app_id && (
               //   <AppBuild app={app} />
               // )
                }
                {show?.what === "CreateNewIds" && show?.app === app.app_id && (
                  <AppNewIds app={app} />
                )}
                {show?.what === "Templates" && show?.app === app.app_id && (
                  <AppTemplates app={app} searchValue={searchValue} />
                )}
                {show?.what === "Schemas" && show?.app === app.app_id && (
                  <AppSchemas app={app} searchValue={searchValue} />
                )}
              </div>
            ))}
      </div>

      <ToastContainer />
    </div>
  );
}

export default Apps;
