import {
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowDown,
  faArrowDownWideShort,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import ContentEdition from "../edition/ContentEdition";
import { data } from "../../service/data";
import { getRecord } from "../../service/localdata";
import { _GetSetTers, contentConvert, loadAllContentAsync, loadCompleteContentAsync } from "../../tools/tpl";
import TemplateX from "../templates/TemplateX";
import { MfctContext } from "../../context";
import VarContent from "../VarContent";
import SelectDropDown from "./SelectDropDown";

const ContentSelect = ({
  id,
  label,
  onChange,
  value,
  className,
  options,
  placeholder,
  trueValue,
  valueType,
  updateContent,
  domain,
  schema_id,
  template_id,
  context
}) => {



 const { getTemplates ,getSchemas} = useContext(MfctContext);
  const [_value, setValue] = useState();
  const [values, setValues] = useState([]);
  const [showEdition, setShowedition] = useState();
  const [roptions, setROptions] = useState(options);
  const [displayTemplate, setDisplayTemplate] = useState();
  const [valueContent, setValueContent] = useState();
  const [contents, setContents] = useState([]);

  var templates = getTemplates();
 var schemas =getSchemas();

//  var templates = [];
 // var schemas =[];

  useEffect(() => {

const getContent= async ()=>{
  const content_id= typeof value==="object"&&  value.content_id ? value.content_id :value
  if(content_id)  
 {
   var v =    await data.getContent(content_id,true)
setValueContent(v)

 }
 else setValueContent()
}

if(  value) getContent()
else
{
  setValueContent()
    setValue();
 }
 }, [value, trueValue]);





const GetSetTers = (template, getSelf, setSelf,content_id) => {

  return { ..._GetSetTers( template,getSelf,setSelf,"global",[{content_id:"global",content:{self:{content_id}}},...contents],displayTemplate.props_from_templates ??{}  , schemas )
  ,GetSetTers}
  
  
    };

  console.log(`ShowOptions For Label ${label}`, options);

  const getOptions = async () => {

   
    var app
    if(context?.app_id)
      {
        app = await data.getApp(context?.app_id, true);
      }
   else  app = await data.getAppByDomain(domain,true);
    if (!app) {
      console.error("Couldn't find application", domain);
      return;
    }

    var _values =[]
    
   if( true)
    _values=  await data.getContents(
      (c) =>
      (c.is_public ||  c.app_id + "" === "" + app.app_id) &&
    ( schema_id ===509 ||     c.schema_id + "" === "" + schema_id) && (  !context?.filter || context.filter(c)) ,
      true,true
    );
    console.log(`Find Resutl $$$$$$$$$$$ ${label}`, _values, schema_id, app.app_id);

   
    if(template_id)
      {
        console.log(`Find template_id $$$$$$$$$$$ `,template_id);
        var _contents =[]
        for(let content of _values){
          _contents.push(content)
         _contents = await loadAllContentAsync(content,_contents,null,true)
        }
        setContents(_contents)
      }
      setValues(_values)
    if (template_id && false ) {

 
      var _template = await getRecord(parseInt(template_id), "template");
      setDisplayTemplate(_template);
      console.log(_template);
      var contents = [];
      for (var i = 0; i < _values.length; i++) {
        var cContent = await loadCompleteContentAsync(_values[i]);
        contents.push(cContent);
      }
   ;
    } else {
      setROptions(
        _values.map((v) => ({ value: v.content_id, label: v.title }))
      );
    }
  };

  useEffect(() => {
    if ( !roptions && (domain || context?.app_id)) {
     getOptions();
      console.log("Get Options YESS",{label,roptions,domain})
    }
    else {
      console.log("No Options Already OK",{label,roptions,domain})
    }
  }, [roptions,context, domain, template_id]);

  const [showSelection, setShowSelection] = useState();

  return  <>
  
      {displayTemplate && 
        <div
          className={
            " relative flex flex-row items-start  border border-slate-400  rounded-lg  justify-start" +
            className
          }
          onClick={() => !showSelection && setShowSelection(true)}
          onBlur={()=>setShowSelection(false) }
        >{label}
          <div className="flex flex-col w-full ">

          <div className="flex flex-row w-full items-center justify-center ">
           <TemplateX
              content={{...GetSetTers (displayTemplate, null, null,valueContent?.content_id)  }
             }
              template={displayTemplate.structure}
              templates={templates}
              selectTpl={()=>!showSelection && setShowSelection(true)}
              id={id +"value." +valueContent?.content_id }
              safeDisplay={true}
            />
                      <div className="p-2    ">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
          </div>

{
showSelection &&
    <div className="w-full flex flex-col  absolute left-0 bg-white top-full z-50  overflow-y-auto border border-slate-400 rounded-lg shadow-md"
style={{maxHeight:"50vh"}}
>{              values
                .filter((c) => c.content_id + "" !== "" + _value)
                .map((c, i) => (
                  <div
                    className="appbutton w-full flex flex-col  "
                    key={i}
                    onClick={() => {
                      onChange(c.content_id);
                      setShowSelection(false);
                    }}
                  >
                    <TemplateX
                     content={{...GetSetTers (displayTemplate, null, null,c.content_id) } }
                      template={displayTemplate.structure}
                      templates={templates}
                      selectTpl={()=>{
                        onChange(c.content_id);
                        setShowSelection(false);
                      }}
                      id={"select." +c.content_id }
                      safeDisplay={true}
                    />
                  </div>
                ))}
          
          </div>
          
          }

        </div>
        </div>
      }
      {!displayTemplate && (
        <div className={" relative flex  flex-col items-center " + className}>
         
     <SelectDropDown 
     id={id}
  mainClassName="w-full "
      className={"w-full"}               
label={label}



optionsFilter={!_value || !context?.prop?.is_owner  ? ` schema_id = ${schema_id} ` : ` schema_id = ${schema_id} AND content_id =  ${_value} `}
filter={ context?.filter}
 /* 
 OR JSON_EXTRACT(content, "$.${prop.prop_title}.content_id") <> ${content.content_id} */
value={valueContent?.title}
onChange={async(value)=>{



  onChange(value.content_id)
}}

fieldId={"content_id"}
fieldSearch={"title"}
callClose={()=>{}}
placeholder={     `-- ${placeholder} --`}
OptionRender={(c)=><div>{c.value.title}</div>}
dir={"bottom"}
/>
    
          <label
            htmlFor={"input_" + label}
            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            {label}
          </label>
          {false && value && (!context?.minimized ) &&  (
            <FontAwesomeIcon
              onClick={() => setShowedition(!showEdition)}
              className="p-1"
              icon={!showEdition ? faArrowCircleDown : faArrowCircleUp}
            />
          )}
        </div>
      )}
      {showEdition && value && (!context?.minimized ) &&  (
        <div className="mb-3 p-3 rounded-md w-full shadow-md shadow-black">
          <ContentEdition
            content_id={value}
            dataOnly={true}
            updateLiveContent={updateContent}
            domain={domain}
            context={context}
          />
   
        </div>
      )}
    </>

};
export default ContentSelect;
