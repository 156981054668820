import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";

import ContentEdition from "../../../../../components/edition/ContentEdition";
import VarContent from "../../../../../components/VarContent";
import { useEffect, useState } from "react";

import InputSelect from "../../../../../components/inputs/InputSelect";
import { retrieveProject } from "../../../../../service/mftc-api";
import { loadActions, runAction, runActionSync } from "../../../../../tools/actions";
import InputBoolean from "../../../../../components/inputs/InputBoolean";
import InputData from "../../../../../components/inputs/InputData";
const ShowTestingContent = ({
  setShow,
  schemas,
  testContent,
  updateTestContent,
  changePropContent,
  setInternal,
  propsTemplates,
  infos,   
  suidTemplates,
  GetActions,
  template
}) => {

 

  const [testingActions, setTestingActions] = useState();

  const[apps,setApps]=useState([])

  useEffect(()=>{
  
  const init=async()=>{
  
    var _apps = await data.getApps()
    setApps(_apps)
  }
  
  
  init()
  },[])
  

  useEffect(() => {
    var actions =GetActions()  
    loadActions( actions);
    setTestingActions(actions)
  }, []);


if(!testContent) return <>ERREUR </>
  
  return (
    <div id={"ShowTestingContent"} className=" flex-none  w-80  bg-white m-2 flex flex-col items-center justify-center border border-white rounded-xl shadow">
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
        <FontAwesomeIcon icon={fa.faNoteSticky}></FontAwesomeIcon>
        Données de template
        <FontAwesomeIcon
          icon={fa.faClose}
          onClick={() => setShow((show) => ({ ...show, Data: !show.Data }))}
          className="appbutton"
        ></FontAwesomeIcon>
      </div>
      { 
[{suid:"", title:"Global" }, ...Object.keys(suidTemplates).map(s=>({suid:s,...suidTemplates[s]  })) ].map(grp=>(<div className={"flex flex-col w-full py-2 gap-1"}>
  <div key={grp.suid} className="w-full p-2 font-bold bg-slate-300">{grp.title} {grp.suid ? "/":"" } {grp.suid}</div>
  {
  propsTemplates.filter(p=>(grp.suid && p.name.startsWith(grp.suid)  &&  grp.suid.split("-").length+1===p.name.split("-").length ) || (!grp.suid && p.name.split("-").length===1) ).map(p=> 
    <div key={p.name} className="w-full p-2 text-black">{ p.schema &&
<InputData  className={"w-full"} value={ testContent.content[p.name]    } onChange={(value)=>changePropContent(p.name,value )} prop={{...p,name:p.schema.title + " / " + p.name.split("-").pop() }} schema={p.schema} context={{app_id:template?.app_id}}  />}
{ !p.schema &&  <>{ p.name} en charge</>}
    </div>
   )
  }
</div>))

      }
    {testContent?.content_id}
      <div className="flex flex-col w-full">
    
      

  
        {testingActions &&
          testingActions.map((action, i) => (
            <div
              className="flex w-full flex-row p-2 gap-2 items-center justify-between "
              key={i}
            >
              Tester{" "}
              <div
                className="rounded-md grow text-white bg-blue-600 p-2"
                onClick={(event) =>action.asynchrone ?  runAction(event, action,infos,setInternal) :  runActionSync(event, action,infos,setInternal) }
              >
                {action.title}{" "} 
              </div>
     
            </div>
          ))}

      </div>
    </div>
  );
};

export default ShowTestingContent;
