import "../../App.css";

import {
  BrowserRouter,
  Switch,
  Routes,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import { MfctContext } from "../../context";
import { useEffect, useMemo, useState } from "react";
import { getAuthUser, projectInit, retrieveProject } from "../../service/mftc-api";
import { arrayMerge, getConditions } from "../../tools/tpl";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import { setBackground } from "../../service/background";

import Home from "./pages/home/Home";
import NewApp from "./pages/newapp/NewApp";
import Users from "./pages/users/Users";
import Apps from "./pages/apps/Apps";
import Chat from "./pages/chat/Chat";
import Meet from "./pages/meet/Meet";
import Contents from "./pages/contents/Contents";

import SetTemplate from "./pages/template/SetTemplate";
import Templates from "./pages/templates/Templates";
import Visio from "../../components/visio/Visio";

import SetPage from "./pages/page/SetPage";
import SetContent from "./pages/content/SetContent";
import NotFound from "./pages/notfound/NotFound";
import ContentSchemas from "./pages/contents/ContentSchemas";
import WebPush from "./pages/webpush/WebPush";
import PaymentProcess from "../common/pages/payment_process/PaymentProcess";

import Subscriptions from "./pages/subscriptions/Subscriptions";
import Parameters from "./pages/parameters/Parameters";
import Page from "../client/pages/page/Page";

import PopupPageEditor from "./pages/popupeditor/PopupPageEditorAlone";
import PopupTemplateEditor from "./pages/popupeditor/PopupTemplateEditorAlone";

import {
  deleteRecord,
  getAllRecords,
  getFilterOneRecord,
  getFilterRecord,
  getRecord,
  setRecord,
} from "../../service/localdata";
import SetRole from "./pages/role/SetRole";

import Hostings from "./pages/hostings/Hostings";

import { data } from "../../service/data";
import ContentActions from "./pages/contents/ContentActions";
import Deployments from "./pages/deployments/Deployments";
import ContentLibs from "./pages/contents/ContentLibs";


var caches = {};
const cache = {
  set: function (key, value, force) {
    return;
    console.log(" set Cache " + key + " " + force, value);
    if (!(key in caches) || force) {
      caches[key] = JSON.stringify(value);
    } else console.log(" already Cached ");
  },
  get: function (key) {
    // return
    console.log(key, caches);
    if (caches[key]) return JSON.parse(caches[key]);
  },
  remove: function (key) {
    if (key in caches) delete caches[key];
  },
};
var globalDataLoading;
var getProjectLoading;

function AppPro() {
  console.log("AppPro");
  const [authUser, setAuthUser] = useState(getAuthUser());
  const [project, setProject] = useState();
  const [templates, setTemplates] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [apps, setApps] = useState([]);
  const [classes, setClasses] = useState([]);
  const [contents, setContents] = useState([]);
  const [appContents, setAppContents] = useState({});
  const [appSchemas, setAppSchemas] = useState({});
  const [pages, setPages] = useState([]);


  async function getGlobalData(p) {
    if (globalDataLoading) {
      console.log("globalDataLoading already loaded");
      return;
    }
    try{
    console.log("globalDataLoading loading");
    globalDataLoading = true;
    p = retrieveProject();
    var timestamp = localStorage.getItem("timestamp");
    var body = {};
    if (timestamp) body.timestamp = timestamp;
    var global = await p.api("/get_global_data", { body });

    console.log("Données globales récupérées avec succès");
    if (!timestamp) timestamp = "0000-00-00 00:00:00";
    var tables = Object.keys(global);

    for (let i = 0; i < tables.length; i++) {
      let table = tables[i];
      let records = global[table];
      for (let j = 0; j < records.length; j++) {
        if (timestamp < records[j].updated_at)
          timestamp = records[j].updated_at;

        if (records[j].active === 0) {
          console.log("delete ", table, records[j]);

          await deleteRecord(records[j][table + "_id"], table);
        } else {
          console.log("Add", table, records[j]);

          await setRecord(records[j], table);
        }
      }
    }

    localStorage.setItem("timestamp", timestamp);
    globalDataLoading = false;
    console.log("globalDataLoading terminated");
  }
  catch (err) {

    window.alert("Error: " + err.message)

  }
  }

  async function getProject() {
    if (getProjectLoading) {
      console.log("getProjectLoading already loaded");
      return;
    }
    getProjectLoading = true;
    console.log(
      "getProject",
      process.env.REACT_APP_MFTC_SERVER_URL,
      process.env.REACT_APP_PROJECT_NAME
    );
    const p = await projectInit(
      process.env.REACT_APP_MFTC_SERVER_URL,
      process.env.REACT_APP_PROJECT_NAME
    );
    if(p.updatedUser){ 
      setAuthUser(  getAuthUser())
    }
    p.timestamp = Date.now();
    console.log("project BEFORE glodab data", p.timestamp, p);
    await getGlobalData(p);

    await getTemplatesandClasses(p);

    console.log("START setting project", p.timestamp, p);
    setProject(p);
    console.log("END setting project", p.timestamp, p);
    getProjectLoading = false;
  }

  async function getTemplatesandClasses(p) {
    console.log("getTemplatesandClasses cancelled");
    

    var _templates = await getAllRecords("template");
  
    var _schemas = await getFilterRecord(
      (content) => content.is_schema,
      "content"
    );
    var _apps = await getAllRecords("app");


    setApps(_apps);
    setSchemas(_schemas);
    setTemplates(_templates);
    
    console.log("getTemplatesandClasses ok");
  }

  async function getSchema(content_id, force) {
    if (!force && schemas.find((c) => c.content_id + "" === content_id + ""))
      return schemas.find((c) => c.content_id + "" === "" + content_id);
    var _content = await project.api("/get_content/:content_id", {
      // Un schema est un content
      args: { content_id },
    });

    if (schemas.find((c) => c.content_id + "" === "" + content_id))
      setSchemas(
        schemas.map((c) =>
          c.content_id + "" === "" + content_id ? _content : c
        )
      );
    else setSchemas([...schemas, _content]);
    return _content;
  }

  async function getTemplate(template_id, force) {
    var template = templates.find(
      (t) => t.template_id + "" === "" + template_id
    );
    var save = false;
    var found = !!template;
    if (force || template) {
      template = await project.api("/pro/template/:template_id", {
        args: { template_id },
      });
      save = true;
    }
    if (
      template &&
      !template.classeProps &&
      template.class_id &&
      classes.length > 0
    ) {

      template.conditions = getConditions(template.structure);
      save = true;
    }

    if (save) {
      if (found)
        setTemplates(
          templates.map((t) =>
            t.template_id + "" === "" + template_id ? template : t
          )
        );
      else setTemplates([...templates, template]);
    }
    return template;
  }

  async function updateTemplate(template) {
    if (templates.find((t) => t.template_id + "" === "" + template.template_id))
      setTemplates(
        templates.map((t) =>
          t.template_id + "" === "" + template.template_id ? template : t
        )
      );
    else setTemplates([...templates, template]);
  }

  async function getClasse(classe_id, force) {
    if (!force && classes.find((c) => c.classe_id + "" === classe_id + ""))
      return classes.find((c) => c.classe_id + "" === "" + classe_id);
    var _classe = await project.api("/pro/classe/:classe_id", {
      args: { classe_id },
    });

    if (classes.find((c) => c.classe_id + "" === "" + classe_id))
      setClasses(
        classes.map((c) => (c.classe_id + "" === "" + classe_id ? _classe : c))
      );
    else setClasses([...classes, _classe]);
    return _classe;
  }

  async function getContent(content_id, force) {
    if (!force && contents.find((c) => c.content_id + "" === content_id + ""))
      return contents.find((c) => c.content_id + "" === "" + content_id);

    console.log("not found", contents);
    var _content = await project.api("/get_content/:content_id", {
      args: { content_id },
    });
    console.log("found", _content);
    if (contents.find((c) => c.content_id + "" === "" + content_id))
      setContents(
        contents.map((c) =>
          c.content_id + "" === "" + content_id ? _content : c
        )
      );
    else setContents([...contents, _content]);
    return _content;
  }

  async function updateContent(content) {
    if (contents.find((c) => c.content_id + "" === "" + content.content_id))
      setContents(
        contents.map((c) =>
          c.content_id + "" === "" + content.content_id ? content : c
        )
      );
    else setContents([...contents, content]);
  }

  const getApps = () => {
    return apps;
  };

  function getClasses() {
    return classes;
  }
  function getContents() {
    return contents;
  }
  function getSchemas() {
    return schemas;
  }

  function getTemplates() {
    return templates;
  }
  async function getContentsByApp(app_id) {
    if (appContents[app_id]) {
      return contents.filter((c) => parseInt(c.app_id) === parseInt(app_id));
    }
    var _contents = await getFilterRecord(
      (content) => content.app_id === parseInt(app_id) && !content.is_schema,
      "content"
    );

    setContents([...contents, ..._contents]);
    var _appContents = { ...appContents };
    _appContents[app_id] = true;
    setAppContents(_appContents);
    return _contents;
  }

  async function getSchemasByApp(app_id) {
    if (appSchemas[app_id]) {
      return schemas.filter(
        (c) => !c.app_id || parseInt(c.app_id) === parseInt(app_id)
      );
    }
    var _schemas = await getFilterRecord(
      (content) => content.app_id === parseInt(app_id) && content.is_schema,
      "content"
    );

    var newschemas = [...schemas, ..._schemas];
    setSchemas(newschemas);

    setAppSchemas((s) => ({ ...s, [app_id]: true }));
    return newschemas.filter(
      (c) => !c.app_id || parseInt(c.app_id) === parseInt(app_id)
    );
  }

  async function getPagesByApp(app_id, domain) {
    if (domain) {
      app_id = await getFilterOneRecord((app) => app.domain === domain, "app")
        .app_id;
    }

    var _pages = await getFilterRecord(
      (page) => parseInt(page.app_id) === parseInt(app_id),
      "page"
    );

    setPages((pages) => arrayMerge(pages, _pages, "page_id"));

    return _pages;
  }

  useEffect(() => {

    console.log("getProject setBackground !!!",{authUser});
    setBackground();

    getProject();
  }, [authUser]);

  useEffect(() => {
    serviceWorkerRegistration.updateRegister();
  }, []);

  const LoadApp = () => {
    const { domain } = useParams();
    useEffect(() => {
      // Initialisation de l'application et de ses pages au debut
      var app = apps.find((app) => app.domain === domain);
      if (app) getPagesByApp(app.app_id).then(() => {});
    }, []);

    return <>{JSON.stringify(domain)}</>;
  };

  const  PageWrapper=({ pageDefault, _key })=> {
    const params = useParams(); // Récupérer les params ici

    return <Page pageDefault={pageDefault} key={_key + JSON.stringify(params)} />;
  }

  if (!project) return <></>;
  return (
    <MfctContext.Provider
      value={{
        authUser,
        setAuthUser,
       project,
        getProject,
        getTemplate,
        getClasse,
        //  classes,
        //  templates,
        setTemplates,
        updateTemplate,
        setContents,
        // contents,
        getContent,
        setSchemas,
        getClasses,
        getGlobalData,
        //  schemas,
        getApps,
        getTemplates,
        getSchemas,
        getContents,
        getSchema,
        getContentsByApp,
        getPagesByApp,
        updateContent,
        getTemplatesandClasses,
        getSchemasByApp,
        cache,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/webpush" element={<WebPush />} />
          <Route path="/newapp" element={<NewApp />} />
          {data.isGranted(authUser, null, "user", "*") && (
            <Route path="/users" element={<Users />} />
          )}
          <Route path="/parameters" element={<Parameters />} />
          <Route path="/subscriptions" element={<Subscriptions />} />

          <Route path="/hostings" element={<Hostings />} />

          <Route path="/deployments" element={<Deployments />} />
        
          {data.isGranted(authUser, null, "app", "*") && (
            <>
              <Route path="/apps" element={<Apps />} />
              <Route path="/apps/:app_id" element={<Apps />} />
              <Route path="/apps/:app_id/:what" element={<Apps />} />
              <Route path="/apps/:app_id/:what/:sub_what" element={<Apps />} />
              <Route path="/app/:app_id/contents" element={<Contents />} />
              <Route
                path="/app/:app_id/content/add"
                element={<SetContent key={"new"} />}
              />
                 <Route
                path="/app/add/:schema_id"
                element={<SetContent key={"new"}  />}
              />
              <Route
                path="/app/:app_id/contentTemplate/:template_id/add"
                element={<SetContent key={"new"} />}
              />
              <Route
                path="/app/:app_id/content/add/:schema_id"
                element={<SetContent key={"new"} />}
              />
            </>
          )}
          {data.isGranted(authUser, null, "action", "*") && (
            <>
              <Route path="/actions" element={<ContentActions />} />
              <Route
                path="/action/add"
                element={<SetContent  schema_id={428}  key={"newaction"} />}
              />
            </>
          )}
                    {data.isGranted(authUser, null, "lib", "*") && (
            <>
              <Route path="/libs" element={<ContentLibs />} />
             
            </>
          )}
          {data.isGranted(authUser, null, "schema", "*") && (
            <>
              <Route path="/schemas" element={<ContentSchemas />} />
              <Route
                path="/schema/add"
                element={<SetContent is_schema={true} key={"newschema"} />}
              />
              <Route
                path="/app/:app_id/schema/add"
                element={<SetContent is_schema={true} key={"new"} />}
              />
            </>
          )}


          <Route
            path="/content/:content_id"
            element={<SetContent key={"set"} />}
          />

          {data.isGranted(authUser, null, "role", "*") && (
            <Route
              path="/setRole/:role_id"
              element={<SetRole key={"setrole"} />}
            />
          )}
          <Route
            path="/contentTemplate/:content_id"
            element={<SetContent key={"set"} />}
          />
   <Route
            path="/contentTemplate/:content_id/:template_id"
            element={<SetContent key={"set"} />}
          />

          <Route
            path="/app/:app_id/page/add"
            element={<SetPage key={"new"} />}
          />
          <Route
            path="/app/:app_id/page/add/:type"
            element={<SetPage key={"new"} />}
          />
          <Route
            path="/app/:app_id/page/:page_id"
            element={<SetPage key={"set"} />}
          />


{
data.isGranted(authUser,null,"template","*") &&     
          <>
          <Route path="/templates" element={<Templates />} />

          <Route
            path="/app/:app_id/template/add"
            element={<SetTemplate key={"new"} />}
          />
          <Route
            path="/app/:app_id/template/add/:schema_id"
            element={<SetTemplate key={"xxx"} />}
          />

          <Route path="/template/add" element={<SetTemplate key={"set"} />} />
          <Route path="/template/:template_id" element={<SetTemplate />} />

          <Route
            path="/edittemplate/:template_id"
            element={<PopupTemplateEditor />}
          />
          <Route path="/templateeditor" element={<PopupTemplateEditor />} />
</>
}{  
data.isGranted(authUser,null,"page","*") && 
          <>
          <Route path="/pageeditor" element={<PopupPageEditor />} />
          <Route path="/editpage/:page_id" element={<PopupPageEditor />} />
          </>
}

          {apps &&
            pages &&
            apps.map(app=>({app, page: pages.find((page) => app.app_id === page.app_id) })).
            filter(cpage=> cpage.page).map((cpage, i) => {
var app =cpage.app
var _page = cpage.page
              console.log("Nouvelle Route Application" + app.domain,_page);
              _page.app = app;
              return (
                <Route key={i}
                  path={`/domain/${app.domain}`}
                  element={   <Page pageDefault={_page} key={app.domain} />}
                />
              );
            })}
          {pages &&
            apps &&
            pages.filter(page=>  apps.find((app) => app.app_id === page.app_id))
        .map((page, i) => {
              var app = apps.find((app) => app.app_id === page.app_id);

              if (!app) return <></>;
              page.app = app;

              return (
                <Route key={i}
                  path={`/domain/${app.domain}/${page.slug ?? page.page_id}`}
                  element={
                    <PageWrapper pageDefault={page} _key={page.page_id } />
                  }

                />
              );
            })}

          <Route path="/domain/:domain" element={<LoadApp key={"domain"} />} />
          <Route
            path="/domain/:domain/:slug"
            element={<LoadApp key={"domainslug"} />}
          />
          <Route
            path="/domain/:domain/:slug/:id"
            element={<LoadApp key={"domainslugid"} />}
          />

          <Route path="/chat" element={<Chat />} />
          <Route path="/chat/:account_id" element={<Chat />} />
          <Route path="/meet" element={<Meet />} />
          <Route path="/visio" element={<Visio />} />

          <Route
            path="/domain/:domain/payment_process"
            element={<PaymentProcess />}
          />
          <Route path="*" element={<Home />} />
          <Route path="404" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </MfctContext.Provider>
  );
}

export default AppPro;
