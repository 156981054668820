import { useEffect, useState } from "react"
import { data } from "../service/data"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as fa from "@fortawesome/free-solid-svg-icons";
import React from "react";


const Svg = React.memo(({ content_id,className,style,onClick,id }) => {


const [svg,setSvg]= useState()
 
useEffect(()=>{

        const getSvg= async ()=>{
          
            var _svg = await data.getContent(content_id,true)
           
          setSvg((prevSvg) => (prevSvg !== _svg ? _svg : prevSvg)); 
        }


  getSvg()
},[content_id])

if(!svg) 
    return <></>

 if (svg) {
    return (
      <svg
    
        id={id}
        className={" svg-inline--fa  inline-block  " +className}
        style={style}
        onClick={onClick}
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox={svg.content.viewBox }
        dangerouslySetInnerHTML={{ __html: svg.content.path }}
        
      />
    )
  }

return null

})
export default Svg