import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalPositions, showModal } from "../components/modal";
import { data } from "../service/data";
import { getAuthUser, retrieveProject } from "../service/mftc-api";
import {
  faCheck,
  faPlay,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getPageTemplates } from "./tpl";

var keyFunctions = {};
const actionImports = {};
const actionScripts = {};
const moduleImports = {};
const safeDisplayAction = {};
var context;
console.log("Context Modules project", moduleImports);
var availablesActions;

const getAvailableActions = async (domain, filter) => {
  if (availablesActions) return availablesActions;
  const app = await data.getAppByDomain(domain);
  const _actions = await data.getActions(
    filter //&& ((action) => action.app_id === app.app_id)
  );
  availablesActions = _actions;
  return _actions;
};

const getActions = (mixValue, templates, forceReload) => {
  if (forceReload) keyFunctions = {};
  let actions = [];
  var aTemplates = [];
  const mixValues = Array.isArray(mixValue) ? mixValue : [mixValue];

  for (let mix of mixValues) {
    if (!mix) continue;
    if (mix.template_id) aTemplates = [...aTemplates, mix];
    else if (mix.page_id) {
      //chargement des actions de la page
      aTemplates = [...aTemplates, ...getPageTemplates(mix, templates)];

      if (mix.content?.actions) {
        mix.content.actions.forEach((ea) => {
          if (ea.Action) actions.push({ ...ea.Action, Event: ea.Event });
        });
      }
    } else if (mix.app_id) {
      //chargement des actions de l'application
      if (mix.profile?.actions) {
        mix.profile.actions.forEach((ea) => {
          if (ea.Action) actions.push({ ...ea.Action, Event: ea.Event });
        });
      }
    }
  }

  for (let template of aTemplates) {
    console.log("GetActions Modules :", moduleImports);
    let structures = [{ structure: template.structure, path: {}, self: null }];

    while (structures.length > 0) {
      var Tstructure = structures.shift();
      var structure = Tstructure.structure;
      var self = Tstructure.self;

      if (structure.actions) {
        structure.actions.forEach((ea) => {
          if (ea.Action) actions.push({ ...ea.Action, self, Event: ea.Event });
        });
      }

      if (structure.computed) {
        structure.computed.forEach((computed) => {
          if (!computed || !computed.action_id) return;
          //    console.log("GAction Computed  :", computed);

          actions.push({ ...computed, self, Event: null });
        });
      }
      if (structure.action_click) {
        console.log(
          "Tstructure action ",
          structure.action_click.title,
          Tstructure
        );
        actions.push({ ...structure.action_click, self, Event: "onClick" });
      }
      if (structure.children && structure.children.length > 0)
        structures = [
          ...structures,
          ...structure.children.map((s) => ({ structure: s, self, path: {} })),
        ];

      if (structure.template_id) {
        var tpl = templates.find(
          (tp) => tp.template_id + "" === "" + structure.template_id
        );
        //  console.log("GetActions Sous Template", structure);
        if (tpl)
          structures = [
            ...structures,
            {
              structure: tpl.structure,
              self: structure.schema_label ?? structure.schema_prop ?? null,
              path: {},
            },
          ];
      }
    }
  }

  return actions;
};

const loadImport = (obj_import) => {
  console.log("Import Module " + obj_import);
  if (!context) {
    console.log("Import Module " + obj_import);
    context = require.context("../.", true, /\.js$/);

    context.keys().forEach((key) => {
      const componentName = key.replace(/^.+\/([^/]+)\.js?$/, "$1");
      try {
        for (const [k, v] of Object.entries(context(key))) {
          moduleImports[componentName + ":" + k] = v;
          if (k !== "default") moduleImports[k] = v;
        }
      } catch (e) {
        console.error(e);
        console.log("error " + key);
      }
    });

    console.log("moduleImports", moduleImports);
  }

  try {
    if (moduleImports[obj_import]) {
      return moduleImports[obj_import];
    } else {
      console.log("NO IMPORT " + obj_import, moduleImports);
    }
  } catch (error) {
    console.error("Erreur lors du chargement du module:", error);
    return null;
  }
};

const runActionSync = (event, action, content, setInternal, safeDisplay) => {
  if (safeDisplay && !safeDisplayAction[action.action_id])
    return showModal("", {
      key: "action.action_id",
      closeDelay: 5000,
      position: ModalPositions.RIGHTTOP,
      className: "",
      standalone: true,
      preventAction: false,
      addContent: true,
      darkMode: false,
      content_id: action.action_id,
      Content: (
        <div
          className="bg-blue-500 p-2 text-xs text-white flex flex-row items-center justify-start"
          onClick={() => {
            safeDisplayAction[action.action_id] = true;
            runActionSync(event, action, content, setInternal);
          }}
        >
          <FontAwesomeIcon
            icon={faPlayCircle}
            className=" p-1 text-green-500 "
          />{" "}
          {action.title}
        </div>
      ),
    });
  var args = {};
  var setArgs = {};


  Object.keys(action.Arguments).forEach((key) => {
    var keyContent = action.Arguments[key].value;
    var Argument = action.Arguments[key];
    /*  if (
      (keyContent === "self" || (!keyContent && key === "self")) &&
      action.self
    )
      keyContent = action.self;
*/
    if (!keyContent) keyContent = key;
    if (action.Arguments[key].is_noset_label) {
      args[key] = keyContent;
    } else {
      if (Argument.type_put !== "out") {
        args[key] = content["_get_" + keyContent]
          ? content["_get_" + keyContent].do()
          : null;

        if (!content["_get_" + keyContent]) {
          console.log("_get_" + keyContent + "  n'hesite pas !!!");
          return;
        }
        console.log("Synchrone  Stack Get ", {
          content_id: content.content_id,
          key,
          keyContent,
          stack: content["_get_" + keyContent].stack,
          value: content["_get_" + keyContent].do(),
        });
      }
      setArgs["set" + key] = content["_set_" + keyContent]
        ? content["_set_" + keyContent].do
        : (value) => setInternal(keyContent, value);

      if (content["_post_" + keyContent])
        setArgs["post" + key] = content["_post_" + keyContent].do;
      if (content["_open_" + keyContent])
        setArgs["open" + key] = async (slug, index) =>
          content["_open_" + keyContent].do(content.navigate, index, slug);
      if (content["_popup_" + keyContent])
        setArgs["popup" + key] = async (index, template_id) =>
          content["_popup_" + keyContent].do(index, template_id);
      //    post si on a le droit et si c'est objet etc..
    }
  });

  if (action.auth) {
    setArgs["authUser"] = getAuthUser();
  }

  console.log(action.title + " SyncCall Arguments action :", args);
  if (!keyFunctions["action_" + action.action_id]) {
    console.log(action.title + "Importation ", args);
    loadAction(action).then(() => {
      // window.alert(action.title +" Chargée")
      keyFunctions["action_" + action.action_id](
        { event, e: event, content, ...args },
        { ...setArgs, event, navigate: content.navigate, e: event }
      );
    });
  } else if(typeof keyFunctions["action_" + action.action_id] ==="function"){
    console.log(action.title + " la fonction existe  >>> ", keyFunctions["action_" + action.action_id] );
    var result = keyFunctions["action_" + action.action_id](
      { event, e: event, content, ...args },
      { ...setArgs, event, e: event }
    );
    console.log(action.title + " Result  Arguments action :", { args, result });
    return result;
  }
  else 
  {
    console.log(action.title + " la fonction est en chargement ", );
    return { process:"loading"}
  }
};

const runAction = async (event, action, content, setInternal, safeDisplay) => {
  if (safeDisplay && !safeDisplayAction[action.action_id])
    return showModal("", {
      key: "action.action_id",
      closeDelay: 5000,
      position: ModalPositions.RIGHTTOP,
      className: "",
      preventAction: false,
      addContent: true,
      standalone: true,
      darkMode: false,
      content_id: action.action_id,
      Content: (
        <div
          className="bg-blue-500 p-2 text-xs text-white flex flex-row items-center justify-start"
          onClick={async () => {
            safeDisplayAction[action.action_id] = true;
            await runAction(event, action, content, setInternal);
          }}
        >
          <FontAwesomeIcon
            icon={faPlayCircle}
            className=" p-1 text-green-500 "
          />{" "}
          {action.title}
        </div>
      ),
    });

  var args = {};
  var setArgs = {};
  console.log("Action Asynchrone !!!!!", action.title, event);
  Object.keys(action.Arguments).forEach((key) => {
    var keyContent = action.Arguments[key].value;
    /*   if (
      (keyContent === "self" || (!keyContent && key === "self")) &&
      action.self
    )
      keyContent = action.self;
*/

    if (!keyContent) keyContent = key;
    if (action.Arguments[key].is_noset_label) {
      args[key] = keyContent;
    } else {
      args[key] = content["_get_" + keyContent]
        ? content["_get_" + keyContent].do()
        : null;

      setArgs["set" + key] = content["_set_" + keyContent]
        ? content["_set_" + keyContent].do
        : (value) => setInternal(keyContent, value);
      console.log(
        " Stack Get " +
          {
            key,
            keyContent,
            stack: content["_get_" + keyContent].stack,
            value: content["_get_" + keyContent].do(),
          }
      );
      //    post si on a le droit et si c'est objet etc..
      if (content["_post_" + keyContent])
        setArgs["post" + key] = content["_post_" + keyContent].do;
      if (content["_open_" + keyContent])
        setArgs["open" + key] = async (slug, index) =>
          content["_open_" + keyContent].do(content.navigate, index, slug);
      if (content["_popup_" + keyContent])
        setArgs["popup" + key] = async (index, template_id) =>
          content["_popup_" + keyContent].do(index, template_id);
    }
  });

  if (action.auth) {
    setArgs["authUser"] = getAuthUser();
  }

  console.log("Arguments action :", args);
  if (!keyFunctions["action_" + action.action_id]) {
    await loadAction(action);
  }
  if(typeof keyFunctions["action_" + action.action_id] ==="function")
  await keyFunctions["action_" + action.action_id](
    { event, e: event, content, ...args, index: action.index },
    { ...setArgs, navigate: content.navigate, event, e: event }
  );
};

const loadActions = async (actions) => {
  for (let action of actions) {
    await loadAction(action);
  }
};

const loadAction = async (action, update = false) => {
  if (keyFunctions[`action_${action.action_id}`] && !update) return;
  keyFunctions[`action_${action.action_id}`] ="loading";

  var actionScript = actionScripts[action.action_id];
  if (!actionScript) {
    var project = retrieveProject();
    const api = project.api;
    actionScript = await api("/load_ihm_action/:action_id", {
      args: { action_id: action.action_id },
    });
    actionScripts[action.action_id] = actionScript;
  }

  actionScript.endpoints?.forEach((endpoint) => {
    project.endpoints[endpoint.url] = endpoint;
    console.log("MISE A JOUR ENDPOINT ACTION" + endpoint.url);
  });

  //  if(action.Imports) window.alert("Import " + JSON.stringify(action.Imports))
  console.log("IMPORT ACTION ", action);
  for (let key of action.Imports ?? []) {
    if (!actionImports[key.split(":").pop()]) {
      console.log("IMPORT " + key);
      actionImports[key.split(":").pop()] = await loadImport(key);
    }
  }
  const argsKeyIn = Object.keys(action.Arguments).filter(
    (k) => action.Arguments[k].type_put !== "out"
  );
  const [awaitKeyword, asyncKeyWord] = action.asynchrone
    ? ["await", "async"]
    : ["", ""];
  const importScripts =
    action.Imports?.length > 0
      ? `const {${Object.keys(actionImports).join(",")}}= actionImports;`
      : "";
  console.log("actionImports =>", actionImports);
  const script = `keyFunctions["action_${
    action.action_id
  }"]= ${asyncKeyWord}(props,{${[
    ...argsKeyIn.map((key) => "set" + key),
    ...argsKeyIn.map((key) => "post" + key),
    ...argsKeyIn.map((key) => "open" + key),
    ...argsKeyIn.map((key) => "popup" + key),
    ...(action.auth ? ["authUser"] : []),
    "event",
    "e",
    "navigate",
  ].join(",")}})=>{
   ${importScripts} 

      const fx = ${actionScript.script} 
      if(typeof fx !== "function") {
      console.log("fx is not a function")
      return 
 } return ${awaitKeyword} fx(props)
       }`;
  console.log("SCRIPT  ", script);
  eval(script);
  //window.alert("Chargement " + JSON.stringify(script))
};

export {
  getAvailableActions,
  getActions,
  runAction,
  runActionSync,
  loadActions,
  loadAction,
};
