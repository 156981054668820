import React,{  useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFile, faImage, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";


import ReactDOM from 'react-dom';

import { retrieveProject } from "../../service/mftc-api";


const ChooseFile = ({value,label, onChange,className,domain,placeholder,defaultValue,immersiveMode}) => {



const id = "ChooseFile" +label ;

const  project= retrieveProject();
const [show,setShow]=useState()
const [editUrl,setEditUrl]=useState()
const [files,setFiles   ]= useState([]);



const getFiles=async ()=>{

  var _Files = await project.getFiles(domain ? "apps/" + domain : ""  );

  setFiles(_Files);
}

useEffect(()=>{


  if(show  )  getFiles()
},[show,domain,getFiles])
    



function onUploadProgress(progressEvent){
    const { loaded, total } = progressEvent;
    let precentage = Math.floor((loaded * 100) / total);
//    setProgression({precentage,loaded, total})
    console.log(precentage);

  if( precentage===100)
  {
   getFiles();
  }
}

const onSelect=(image)=>{

    onChange(image);
    setShow(false)
};

  function handleImgChange(e) {
      console.log(e.target.files);
      if(e.target.files.length===0) return 
      setFiles([URL.createObjectURL(e.target.files[0]),...files]);

 project.uploadFile( e.target.files, onUploadProgress, domain ? "apps/" + domain : "" ).then( results=>{

  if( results && results.length===1){
    console.log( results[0]);

  }
 })


}
if((typeof value)==="object") value=null
var labele = value &&  (value).split("/").pop()
if(labele && labele.length===0) labele= null
console.log("Value",typeof value)
return  <>{
immersiveMode ? 

<div className={className +" overflow-hidden" } onClick={()=>setShow(true)} >
{(defaultValue || value)  && <img  src={value ?? defaultValue}  className=" w-full h-full object-cover" alt="im"  /> }
</div>
:
<div className="flex flex-col w-full">
<label htmlFor={ "input_"+label } className="text-left w-4 text-sm text-gray-500 z-40 duration-300 transform translate-y-2 scale-75 top-2  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
<div className={" flex flex-row justify-between items-center relative border-zinc-400 rounded-xl border p-2 overflow-hidden " + className} >

    {(defaultValue || value)  && <FontAwesomeIcon icon={faFile }  value={value ?? defaultValue}  className=" w-12 h-12 object-cover" alt="im"  onClick={()=>setShow(true)} /> }
    {(!defaultValue && !value)  && <div   className=" w-16 h-16 bg-gray-400 rounded-md" ></div> }
    <div className="flex w-full">
  
    {value?.split("/").pop()}
    </div>
 { value ? <FontAwesomeIcon className=" text-red-600 p-2" icon={faClose}  onClick={()=>onChange(null)   }  /> :
 <FontAwesomeIcon className="opacity-0 p-2" icon={faClose}     />    }
</div></div>}
{show &&  
 ReactDOM.createPortal(    <div id={id } className="  fixed h-screen w-screen p-4 top-0 left-0 bg-black bg-opacity-90 text-2xl font-bold flex flex-col justify-start items-center  overflow-auto" style={{zIndex:500000}} >
       <div className="w-full p-4 text-white flex flex-row justify-between "> <span> Choisir un fichier {label} - {domain}</span>   <FontAwesomeIcon className="appbutton" onClick={()=>setShow(false)} icon={faClose } ></FontAwesomeIcon> </div>
        <div className="w-full  flex flex-wrap  p-4 gap-2">
    
 <div   onClick={ ()=>{ document.getElementById("image-upload-" + label ).click()}}  className=" m-2 w-20 h-20 rounded-xl bg-white text-2xl "><FontAwesomeIcon icon={faPlusCircle} ></FontAwesomeIcon> </div>
 <input type="file" accept={"*"} name="file" id={"image-upload-"+label} className="hidden" onChange={handleImgChange} required />

{
    files.map((file,i)=><div  key={i} className="border p-2 gap-2 flex flex-row text-xs text-white" ><FontAwesomeIcon icon={faFile }  key={i}  className={"  w-12  h-12 object-cover bg-black m-2 "  } alt={file} onClick={()=>{ if (file.includes("https:"))onSelect(file)}} />{file.split("/").pop()}</div>)
}

        </div>
        
</div> ,   document.getElementById('root'))
}</>

}
export default ChooseFile