

const getAppColors = (app,bw=false,tw=false)=>{
var colors=[]
if(app?.profile) {

if(app.profile.primary_color) colors.push("primary_color") 
if(app.profile.secondary_color) colors.push("secondary_color") 
  app.profile.appColor.forEach((element,i) => {
if(element)  colors.push("app-color-"+(i+1)   )
    
  });
}
if(bw)
{
    colors.push("black")
    colors.push("white")
    colors.push("transparent")
}
if(tw)
{

}

return colors
}

const getClassColorDefined= (pref,className,app)=>{

var parts =( className+""  ).split(" ")

var appc = getAppColors(app,true,true)

var classColor =    parts.find(cl=>   appc.find(ac=> cl=== pref+ "-"+ ac    )         ) 

return classColor

}






export{ getAppColors,getClassColorDefined}
