
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import InputText from "../../../../../components/inputs/InputText";
import InputSelect from "../../../../../components/inputs/InputSelect";
import InputMultiSelect from "../../../../../components/inputs/InputMultiSelect";
import InputBoolean from "../../../../../components/inputs/InputBoolean";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SelectDropDown from "../../../../../components/inputs/SelectDropDown";
import ContentSelect from "../../../../../components/inputs/ContentSelect";
const TemplateParams = ({template,setShow,schemas,roles,conditions,internalContent,setInternalContent,deleteTemplate,key_schemas}) => {

const[apps,setApps]=useState([])

useEffect(()=>{

const init=async()=>{

  var _apps = await data.getApps()
  setApps(_apps)
}


init()
},[])

    if(!template ) return <></>  
    return    <div id={"TemplateParams"}className=" flex-none p-2 w-80  m-2 flex flex-col items-center justify-center border-t border-white shadow">
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
      <FontAwesomeIcon icon={fa.faGear}></FontAwesomeIcon>
        Paramètres Template 
        <FontAwesomeIcon icon={fa.faClose}  onClick={ ()=> setShow((show) => ({ ...show, Gear: !show.Gear })) }  className="appbutton"  ></FontAwesomeIcon>
        </div>
    
          {
            <>
              <InputText
                value={template.title}
                label={"Titre"}
                className="text-black w-full my-1"
                onChange={(title) => {
                  data.updateTemplate({ ...template, title });
                }}
              />
              <div className="flex flex-row w-full">

                  <SelectDropDown  
              label={"Schéma"}
              placeholder={"Choisir le schéma"}
   mainClassName="text-black w-full my-1 text-sm"
              value={template.schema_id}
              className={"text-black w-full bg-white text-sm"}
              
              fieldId={"content_id"}
              fieldSearch={"title"}


              onChange={(schema) => {


                if(template.schema_id ===schema?.content_id){
                  schema = null
                }
                data.updateTemplate({ ...template, schema_id:schema?.content_id?? null });
              }}
              options={schemas}
              />
                <InputBoolean
                  
                  value = {  template?.is_list }
                       labelTrue={"Liste"}
                       onChange={(is_list) => {
                   
                         data.updateTemplate({
                           ...template,
                           is_list
                         });
                       }}
                     />
                </div> <div className="flex flex-row w-full">
                <InputSelect
                className={"w-full"}
                  label="Layout"
                  placeholder={"Bloc Page"}
                  value={template.layout}
                  onChange={(layout) => {
                    data.updateTemplate({ ...template, layout });
                  }}
                  options={["in", "bloc", "top", "bottom", "left", "right","list","input","select","view","popup","element","structure"]}
                />


              </div>

              <SelectDropDown  
              label={"Application"}
              placeholder={"Choisir l'application"}
   mainClassName="text-black w-full my-1 text-sm"
              value={template.app_id}
              className={"text-black w-full bg-white text-sm"}
              
              fieldId={"app_id"}
              fieldSearch={"name"}

              onChange={(app) => {
                data.updateTemplate({ ...template, app_id:app?   parseInt(app.app_id) :null });
              }}
              options={apps}
              />



              <InputMultiSelect
                label={"Template Size"}
                value={template.structure.sizes}
                onChange={(sizes) => {
                  data.updateTemplate({
                    ...template,
                    structure: { ...template.structure, sizes },
                  });
                }}
                values={["full","fit","screen","xs", "sm","lg","xl",
                  "w-full","w-fit","w-screen","w-xs", "w-sm","w-lg","w-xl",
                "h-full","h-fit","h-screen","h-xs", "h-sm","h-lg","h-xl"].map((size) => ({
                  key: size,
                  label:size,
                }))}
              />
              <InputMultiSelect
                label={"Roles"}
                value={template.structure.roles}
                onChange={(roles) => {
                  data.updateTemplate({
                    ...template,
                    structure: { ...template.structure, roles },
                  });
                }}
                values={roles.map((role) => ({
                  key: role.role_id,
                  label: role.content.title ?? "role " + role.role_id,
                }))}
              />
              <InputBoolean
                  
             value = { Object.values( template?.meta?? {}).length>0 }
                  labelTrue={"Meta"}
                  onChange={(is_meta) => {
              
                    data.updateTemplate({
                      ...template,
                      meta: is_meta ?{is_meta} :null,
                    });
                  }}
                />
              {conditions.map((condition, index) => (
                <InputBoolean
                  key={index}
                  labelFalse={condition + " NO"}
                  labelTrue={condition + " OK"}
                  onChange={(value) => {
                    var c = { ...internalContent };
                    c["_" + condition] = value;
                    setInternalContent(c);
                  }}
                />
              ))}
            </>
          }
       <Link
              to={`/edittemplate/${template.template_id}`}
              target={`editor_template_${template.template_id}`}
              className="w-full appbutton p-2 underline"
            >
       Aller sur ancien éditeur  </Link>
       {deleteTemplate &&   <div className="rounded-xl my-1 w-full bg-red-600 text-white p-2 appbutton " onClick={deleteTemplate}> Supprimer Template  
          
          
   <FontAwesomeIcon   icon={fa.faTrash}/>

            </div>}
        </div>
      };
  


      export default TemplateParams