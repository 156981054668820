import { useContext, useEffect, useState } from "react";
import { MfctContext } from "../../context";
import InputSelect from "./InputSelect";
import { data } from "../../service/data";



const InputLink = ({id,label,onChange,value,className,domain,context}) => {

const [options,setOptions]=useState([])


useEffect(()=>{
const init= async()=>{
    console.log("init Pages " +domain)
    var app = await data.getAppByDomain(domain ?? context?.app_id)
    console.log("init Pages " ,app )
    var _pages =await   data.getPages( (page)=>parseInt(page.app_id)===parseInt( app.app_id) ) 
   
console.log("Pages ",  _pages)
var links= []
_pages.forEach(_page => {
 var slug ="/" +(app.profile.homepage_id+""===""+ _page.page_id ?"":_page.slug  )
    links.push({label: _page.title  + `(${slug})`,value:slug })
     
    _page.content?.body?.forEach(c=>    {
      if(c.label ) {
        links.push({label: _page.title +" #" + c.label,value:slug +"#" + c.label})
      }
    })
  
});

setOptions(links)
}
if(domain || context?.app_id) init()
},[domain,context?.app_id])
   

return (
    <>
    <InputSelect id={id} label={label} onChange={onChange} value={value}  className={className} options={options} placeholder={"- Choisir un lien-"} />
</> 
)
}
export default InputLink



