
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModalPositions, showModal } from "../components/modal"
import { FlatObject, arrayMerge } from "../tools/tpl"
import { deleteRecord, getFilterRecord, getRecord, setRecord } from "./localdata"
import { getAuthUser, retrieveProject, updateAuthuser } from "./mftc-api"
import { socket, unwatch, watch } from "./socket"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

var _data

const data = {

    init: async (record, record_id, live, project, sets, user,force_close,loadedContents={}) => {
        console.log("data init", record, record_id, live, user)
        // subject == {page_id :  ... } 
        if (_data) {
            console.log("data init to close",_data)
           // return
          if( force_close) data.close(_data.record,_data.record_id)
          else 
      {
        console.log("Cant Open Data")
        return 
      }
        }
        console.log("Open Data "  +live  )
        user = user ?? {}
        user.emitor = "E-" + Date.now()
        _data = { record, record_id, live, sets: sets ?? {}, project, lives: {}, complete: [], user ,loadedContents}

        if (live) {
            _data.watch_id = watch(record + "/" + record_id, data.receiveMessage)
            //on va chercher tous les lives ici
            _data.lives = await _data.project.api("/datalive/:record/:record_id", { args: { record, record_id } })
            if (_data.sets.setSaveAll) _data.sets.setSaveAll(_data.lives)
        }



    },

    setAuthUser:async (authUser)=>{

  var blnModified =updateAuthuser(authUser)
        if(blnModified)
          {
          await data.getGlobalData()
          }
        //update des données 
         
    },
   getAuthUser:()=>{
      return getAuthUser()
   },
    deps: (deps) => {
        socket.emit({ deps, setDeps: true })
    },
    isStarted:( record, record_id )=>{

     return(_data && _data.record === record && _data.record_id === record_id)  

    } ,
    close: async (record, record_id) => {


        if (_data && _data.record === record && _data.record_id === record_id) {
            console.log("Close Data")
            if (_data.live) {
                _data.watch_id = unwatch(_data.watch_id)
            }

            _data = null
        }
    },

    updateSets:(  sets =>{
        Object.keys(sets).forEach( key=>    _data.sets[key] = sets[key])

    }),

    getPropSchema:async (schema_prop,schema_id) => {
          if(_data && _data.sets.getPropSchema) return await _data.sets.getPropSchema(schema_prop,null,schema_id)
          return {prop:{ name :schema_prop  }, schema:{ content:{code:'text'}}}
    },
    receiveMessage: async (props) => {

        console.log("receiveMessage", props)
        // Meme utuilisateur



        if (props.user && _data.sets.setUser) _data.sets.setUser(props.user)
        if (props.user.session_id === _data.user.session_id && props.user.emitor === _data.user.emitor) return;
        var mod
        if (props.endLive) {
            mod = true

            if (_data.record === "template") data.cancelTemplate(null, true)
            if (_data.record === "page") data.cancelTemplate(null, true)

            _data.lives = {}





        }
        if (props.page) {
            mod = true;

            _data.lives["page_" + props.page.page_id] = props.page

            if (_data.sets.setPage) _data.sets.setPage(props.page)
        }

        if (props.template) {
            mod = true;
            _data.lives["template_" + props.template.template_id] = props.template
            if (_data.sets.setTemplate) _data.sets.setTemplate(props.template)
            if (_data.sets.setTemplates) {

                console.log("Update template !!!")
                _data.sets.setTemplates((templates) => arrayMerge(templates, [props.template], "template_id",true))
            }
        }

        if (props.content) {
            mod = true;
            _data.lives["content_" + props.content.content_id] = props.content
            if (_data.sets.setContent) _data.sets.setContent(props.content)
            if (_data.sets.setContents) _data.sets.setContents((contents) => arrayMerge(contents, [props.content], "content_id",true))
            if (_data.sets.setCompleteContents) {
                delete _data.complete["content_" + props.content.content_id]
                await data.loadCompleteContent(props.content)
            }
        }

        if (props.schema) {
            mod = true;
            _data.lives["schema_" + props.schema.content_id] = props.schema
            if (_data.sets.setSchema) _data.sets.setSchema(props.schema)
            if (_data.sets.setSchemas) _data.sets.setSchemas((schemas) => arrayMerge(schemas, [props.schema], "content_id",true))
        }

        if (mod && _data.sets.setSaveAll) {
        
        // on retire les données qui n'ont pas d'id >0   dont les string test par exemple
       
            _data.sets.setSaveAll( Object.keys(_data.lives).filter(key=> !_data.lives[key].test ).map(key=> _data.lives[key]  )  )
        }

    },

    emit: (datas) => {
        if (datas.user) _data.user = { ..._data.user, ...datas.user }
        console.log("emit", _data.record, { [_data.record]: _data.record_id, ...datas, user: _data.user })

        socket.emit(_data.record, { [_data.record + "_id"]: _data.record_id, ...datas, user: _data.user })
    },
    getAppByDomain: async (mixValue) => {
        var apps = await getFilterRecord(app => app.domain === mixValue || app.app_id+"" ===""+mixValue , "app")

        return apps.length > 0 ? apps[0] : null
    },
    getApp: async (app_id, noSet) => {
        var app = await getRecord(parseInt(app_id), "app");
        if (_data?.sets.setApp && !noSet) _data.sets.setApp(app)
        return app
    },
    getApps: async (noSet) => {
        var apps = await getFilterRecord(null, "app");
        if (_data?.sets.setApps && !noSet) _data.sets.setApps(apps)
        return apps
    },
    setApp: async(app,noSet) => { 
         app = await setRecord(app, "app");
        if (_data?.sets.setApp && !noSet) _data.sets.setApp(app)
        return app

    },

    getPage: async (page_id, noSet) => {

        var page
        if (_data?.live && _data.lives["page_" + page_id]) {
            page = _data.lives["page_" + page_id]
        }
        page = await getRecord(parseInt(page_id), "page");

        if (_data?.sets.setPage && !noSet) _data.sets.setPage(page)

        return page

    },
    getPages: async (filter, merge, noSet) => {

        var pages = await getFilterRecord(filter, "page")
        if (_data?.live) {
            pages = pages.map(tpl => _data.lives["pages_" + tpl.page_id] ?? tpl)
        }
        if (_data?.sets.setPages && !noSet) {
            if (merge)
                _data.sets.setPages(_pages => arrayMerge(_pages, pages, "page_id",true))
            else
                _data.sets.setPages(pages)
        }
        return pages
    },
    updatePage: async (page) => {


        console.log("updatePage", page)
        _data.lives["page_" + page.page_id] = page
        if (_data.sets.setPage) _data.sets.setPage(page);
        if (_data.live) data.emit({ page });

    },
    newPage: async (page) => {
        return await data.save_page({ ...{ title: "Page " + Date.now(), content: { body: [{template_id:0}] } }, ...page });
    },
    save_page: async (page) => {

        var _page = await (_data?.project?? retrieveProject()).api("/set_page", { body: page });
        await setRecord(_page, "page")
   if(_data){    if (_data?.sets.setPage) _data.sets.setPage({ ...page, ..._page });
        if (_data?.sets.setPages) _data.sets.setPages(_pages => arrayMerge(_pages, [_page], "page_id",true))
        if (_data?.live && page.page_id) data.emit({ page_id: page.page_id, endLive: true });
       } 
   
        showModal( "" , { closeDelay:1000 ,darkMode:false,position: ModalPositions.CENTER ,Content: <FontAwesomeIcon  icon={faCheck}  className=" text-3xl text-green-500 "  /> }  )
   
        return _page
    },

    cancelPage: async (page, noEmit) => {

        if (page && _data.live && !noEmit) data.emit({ page_id: page.page_id, endLive: true });



        var saves = Object.keys(_data.lives)
        var values = Object.values(_data.lives)
        _data.lives = {}
        for (var i = 0; i < saves.length; i++) {
            var record = saves[i].split("_")[0]
            var id = parseInt(saves[i].split("_")[1])
           if( values[i].test) continue;
            switch (record) {
                case "page":

                    await data.getPage(id)
                    break;

                case "template":
                    await data.getTemplate(id)
                    break;
                case "content":
                    await data.getContent(id, null, !!_data.complete[saves[i]])

                    break;

                default:
            }

        }   

        if (_data.sets.setSaveAll) _data.sets.setSaveAll({})

    },




    getTemplate: async (template_id, noSet,fromServer) => {

        var template

        if (_data?.live && _data.lives["template_" + template_id]) {
            template = _data.lives["template_" + template_id]
          //  return template;
        }
        if(fromServer)
            template =    await retrieveProject().api("/pro/template/:template_id",{args:{template_id}})  
            else
        template = await getRecord(parseInt(template_id), "template");
        if (_data?.sets.setTemplate &&   (_data.record!=="template" || _data.record_id+""=== template_id+"" )     && !noSet) _data.sets.setTemplate(template)
        if (_data?.sets.setTemplates && !noSet) _data.sets.setTemplates((templates) => arrayMerge(templates, [template], "template_id",true))

        return template

    },


    getTemplates: async (filter, merge, noSet) => {

        var templates = await getFilterRecord(filter, "template")
        if ( _data?.live) {
            templates = templates.map(tpl => _data.lives["template_" + tpl.template_id] ?? tpl)
        }
        if (_data?.sets.setTemplates && !noSet) {
            if (merge)
                _data.sets.setTemplates(_templates => arrayMerge(_templates, templates, "template_id",true))
            else
                _data.sets.setTemplates(templates)
        }
        return templates

    },

    getActions: async (filter, merge, noSet) => {

        var actions = await getFilterRecord((a)=>{ return a.schema_id===428 && (!filter || filter(a))}, "content")
       

        return actions

    },


    updateTemplate: async (template,setTemplate) => {
        _data.lives["template_" + template.template_id] = template
        if(setTemplate) setTemplate(template)
            else
        if (_data.sets.setTemplate) _data.sets.setTemplate(template)
        if (_data.sets.setTemplates) _data.sets.setTemplates((templates) => arrayMerge(templates, [template], "template_id",true))

        if (_data.live) data.emit({ template });

    },
    save_template: async (template, noSet) => {

    if(template.props_from_templates)   {

Object.keys( template.props_from_templates).forEach(template_id=>{
if(template.props_from_templates[template_id].props){

    Object.keys( template.props_from_templates[template_id].props).forEach(key_prop=>{

        if(template.props_from_templates[template_id].props[key_prop]) {
            delete template.props_from_templates[template_id].props[key_prop].schema
        }
    })
}

})
        }
            var _template = await  (_data?.project?? retrieveProject()).api("/set_template", { body: template });
        await setRecord(_template, "template")
    if(_data){    if (_data.sets.setTemplate && !noSet) _data.sets.setTemplate({ ...template, ..._template });
        if (_data.sets.setTemplates && !noSet) _data.sets.setTemplates((templates) => arrayMerge(templates, [_template], "template_id",true))
        if (_data.live && template.template_id && !noSet) data.emit({ template_id: template.template_id, endDraft: true });
    }
        showModal( "" , { closeDelay:1000 ,darkMode:false ,position: ModalPositions.CENTER ,Content: <FontAwesomeIcon  icon={faCheck}  className=" text-3xl text-green-500 "  /> }  )
   
        return _template
    },

    newTemplate: async (template) => {
        return await data.save_template({ title: "Template " + Date.now(), structure:{ className: "w-full p-2 bg-white",    children: [] } , ...template });

      
    },
    cancelTemplate: async (template, noEmit) => {

        if (template && _data.live && !noEmit) data.emit({ template_id: template.template_id, endDraft: true, endLive: true });




        var saves =  Object.keys(_data.lives).filter(key=> parseInt("0"+ key)>0 )
        _data.lives = {}
        for (var i = 0; i < saves.length; i++) {
            var record = saves[i].split("_")[0]
            var id = parseInt(saves[i].split("_")[1])

            switch (record) {


                case "template":
                    await data.getTemplate(id)
                    break;
                case "content":
                    await data.getContent(id, null, !!_data.complete[saves[i]])

                    break;

                default:
            }

        }

        if (_data.sets.setSaveAll) _data.sets.setSaveAll({})

    },

    setContents : (contents)=>{
        if (_data?.sets.setContents ){
         console.log("setting contents _data",contents)   
            _data.sets.setContents(_contents => arrayMerge(_contents, contents, "content_id",true))
        }
            contents.forEach(content=>      data.setLoadedContent(content))
    },

    getContent: async (content_id, noSet, complete) => {

       
        var content = data.getLoadedContent(content_id)
        if(content) {
         console.log("cache "+ content_id)
            return content
        }
        if (_data?.live && _data.lives["content_" + content_id]) {
            content = _data.lives["content_" + content_id]

        }
        else
        content = await getRecord(parseInt(content_id), "content");
    if(!content){
        content = await retrieveProject().api("/get_content/:content_id",{args:{content_id}})
    }
    if(!content) return
    noSet=noSet ||    data.setLoadedContent(content)

        if (_data?.sets.setContent  &&   (_data.record!=="content" || _data.record_id+""=== content_id+"" )   &&  !noSet) _data.sets.setContent(content)
        if (_data?.sets.setContents && !noSet) _data.sets.setContents(contents => arrayMerge(contents, [content], "content_id",true))
        if (complete) return await data.loadCompleteContent(content, noSet)
        return content

    },




    getLoadedContent:(content_id)=>{
 //   if((""+content_id).split("_").length===2 )console.log( "search  " + content_id, _data?.loadedContents)
 return  data.loadedContents ? data.loadedContents[content_id]:null
        
    },
    setLoadedContent:(content)=>{
if(!content) {
   console.log("setContent Null!!!")
    return 
} if(!data.loadedContents)     data.loadedContents={} 
var ok = content.content_id in  data.loadedContents
    data.loadedContents[content.content_id]=content
    window.contents= data.loadedContents
    return ok
        //if(_data ) _data.loadedContents[content.content_id]=content
    },
    getCompleteContent: (content_id) => {
        return _data?.complete["content_" + content_id]
    },

    getCompleteContents: async (content_ids,Acontents,schemas) => {
        console.log("getCompleteContents", content_ids)
        var contents = []
        for (var i = 0; i < content_ids.length; i++) {
            var content_id = content_ids[i]
            var _c = Acontents ? Acontents.find(c=> c.content_id+"" ===""+ content_id) : null
            if(_c)
                {
                    contents.push(_c);
             
                }
                else
            if (_data?.complete["content_" + content_id]) {
                contents.push(_data.complete["content_" + content_id])
            }
            else {

                var content = await data.getContent(content_id, true)
                content = await data.loadCompleteContent(content, true,schemas)
                contents.push(content)
            }
        }
        if (_data?.sets.setCompleteContents) _data.sets.setCompleteContents(_contents => arrayMerge(_contents, contents, "content_id",true))
        console.log("getCompleteContents OK", content_ids)
        return contents
    },


   loadEntity: async(entity,id)=>{
     console.log("loadEntity " + entity + " " + id, _data?.loadedContents)
    var project = retrieveProject()
   var record =  await project.api("/get_entity_record",{  body:{entity,id } ,cached:true}   );
   if( !record.content_id ) record = {content_id :entity +"_" + id,title:record.title ?? record.label , content:record }
   data.setLoadedContent(record)
   return record;
   } , 

   loadEntities: async(entity,domain,context)=>{
    var project = retrieveProject()
   var result =  await project.api("/get_entity_records",{  body:{entity,domain,context  } ,cached:true}   );
   result= result.map(v=>({content_id:v.entity_name +"_" +  v.value,title:v.label, content:v   }))
result.forEach(element => {
    data.setLoadedContent(element)
});
console.log(result)
   return result;
   } , 


   loadScopeContent: async (content, noSet,scope,schemas) => {
    // if(!schemas) return content

var result = {content_id:content.content_id, }

     if(!content || !content.content) return content
     var keys = Object.keys(scope);
     var _contents = { content_id: content.content_id, Titre: content.title }
     var schema = schemas.find(s=>s.content_id +"" === ""+content.schema_id)
     for (var i = 0; i < keys.length; i++) {

 

         var prop =(keys[i]==="self") ? { name:"self",schema_id: content.content.self.schema_id ,type_nombre:  Array.isArray(  content.content[keys[i]])? "I":null  }:     schema?.content.schema.find(p=>p.name===keys[i])
         if(!prop ) continue;

     
         var prop_schema= schemas.find(s=>s.content_id +""==="" +prop.schema_id)
  
         console.log("proploadAllContentAsync " + keys[i],{ content ,prop_schema, schema})
         if(schemas &&  keys[i]!=="self" &&(    !prop_schema || prop_schema.content.is_value  )) continue


         if (prop_schema && prop_schema.content.entity)
             {

console.log(" entity search loading  "+  prop_schema.content.entity  )

               let entity_name =  prop_schema.content.entity
         let ids = []
         if (prop.type_nombre=== "I"  || prop.type_nombre=== "F" ) ids = Array.isArray(  content.content[keys[i]]) ?  content.content[keys[i]] :   (  content.content[keys[i]] ? [ content.content[keys[i]]] :[]  )  
           else ids=   content.content[keys[i]] ?   [ content.content[keys[i]]] :[] 
         
         
         // Entity
         for (let id of ids){
           if( typeof id === "object")  id =id.content_id 
           id=  ("" + id ).split("_").pop()
         var content_id= entity_name +"_" + id
         
           if(id  && !data.getLoadedContent(  content_id))
             {
     
         var record = await data.loadEntity (entity_name,id );
         
        
         console.log("Entity proploadAllContentAsync record " + prop.name ,record)

         }
         } 
             
         }
         else
{ 

 let ids = []
if (prop.type_nombre=== "I"  || prop.type_nombre=== "F" ) ids = Array.isArray(  content.content[keys[i]]) ?  content.content[keys[i]] :   (  content.content[keys[i]] ? [ content.content[keys[i]]] :[]  )  
else ids=   content.content[keys[i]] ?   [ content.content[keys[i]]] :[] 



// Entity
for (let c of ids){
if( !c?.content_id ) continue;

let content_id =  c.content_id 
let _c=data.getLoadedContent(  content_id)
if( !_c) {

_c = await data.getContent(content_id ) 
}
if (_c) {
 _c = await data.loadCompleteContent(_c, noSet,schemas)
}

}
 
     }

     }

     var completecontent = { ...content, content: { ...content.content, ..._contents } };
     if(_data && _data.complete && _data.complete["content_" + completecontent.content_id]) return completecontent
     if(_data) _data.complete["content_" + completecontent.content_id] = completecontent;
     if (_data?.sets.setCompleteContents && !noSet) _data.sets.setCompleteContents((contents) => arrayMerge(contents, [completecontent], "content_id",true))

     console.log("loadCompleteContent OK", completecontent)
     return completecontent
 },




    loadCompleteContent: async (content, noSet,schemas) => {
       // if(!schemas) return content
      if(!schemas ) {
         schemas  =   data.loadedSchemas
         console.log( "loadCompleteContent data.loadedSchemas" ,data.loadedSchemas)
       }
       console.log("loadCompleteContent", content)
        if (_data?.complete["content_" + content.content_id]) {
            console.log("loadCompleteContent OK CACHE", content)
            return _data.complete["content_" + content.content_id]
        }
        if(!content || !content.content) return content
        var keys = Object.keys(content.content);
        var _contents = { content_id: content.content_id, Titre: content.title }
        var schema = schemas.find(s=>s.content_id +"" === ""+content.schema_id)
        for (var i = 0; i < keys.length; i++) {
        //   window.alert(JSON.stringify({content_id:content.content_id,i,key:keys[i]}))
    

            var prop =(keys[i]==="self") ? { name:"self",schema_id: content.content.self.schema_id ,type_nombre:  Array.isArray(  content.content[keys[i]])? "I":null  }:     schema?.content.schema.find(p=>p.name===keys[i])
            if(!prop ) continue;

        
            var prop_schema= schemas.find(s=>s.content_id +""==="" +prop.schema_id)
     
            console.log("proploadAllContentAsync " + keys[i],{ content ,prop_schema, schema})
            if(schemas &&   keys[i]!=="self" && (    !prop_schema || prop_schema.content.is_value  )) continue


            if (prop_schema && prop_schema.content.entity)
                {

console.log(" entity search loading  "+  prop_schema.content.entity  )

                  let entity_name =  prop_schema.content.entity
            let ids = []
            if (prop.type_nombre=== "I"  || prop.type_nombre=== "F" ) ids = Array.isArray(  content.content[keys[i]]) ?  content.content[keys[i]] :   (  content.content[keys[i]] ? [ content.content[keys[i]]] :[]  )  
              else ids=   content.content[keys[i]] ?   [ content.content[keys[i]]] :[] 
            
            
            // Entity
            for (let id of ids){
              if( typeof id === "object")  id =id.content_id 
              id=  ("" + id ).split("_").pop()
            var content_id= entity_name +"_" + id
            
              if(id  && !data.getLoadedContent(  content_id))
                {
        
            var record = await data.loadEntity (entity_name,id );
            
           
            console.log("Entity proploadAllContentAsync record " + prop.name ,record)
 
            }
            } 
                
            }
            else
{ 

    let ids = []
if (prop.type_nombre=== "I"  || prop.type_nombre=== "F" ) ids = Array.isArray(  content.content[keys[i]]) ?  content.content[keys[i]] :   (  content.content[keys[i]] ? [ content.content[keys[i]]] :[]  )  
  else ids=   content.content[keys[i]] ?   [ content.content[keys[i]]] :[] 



// Entity
for (let c of ids){
if( !c?.content_id ) continue;

let content_id =  c.content_id 
let _c=data.getLoadedContent(  content_id)
if( !_c) {

_c = await data.getContent(content_id ) 
}


if (_c) {
    _c = await data.loadCompleteContent(_c, noSet,schemas)
}

}
    
        }

        }

        var completecontent = { ...content, content: { ...content.content, ..._contents } };
        if(_data && _data.complete && _data.complete["content_" + completecontent.content_id]) return completecontent
        if(_data) _data.complete["content_" + completecontent.content_id] = completecontent;
        if (_data?.sets.setCompleteContents && !noSet) _data.sets.setCompleteContents((contents) => arrayMerge(contents, [completecontent], "content_id",true))

        console.log("loadCompleteContent OK", completecontent)
        return completecontent
    },

    getSchemaContents:async (schema_id, setFunc,merge, noSet  )=>{
          var contents =await data.getContents((c)=> c.schema_id===schema_id ,merge,noSet  )
          if(setFunc) setFunc(contents)
         return contents;
    }
,
    getContents: async (filter, merge, noSet) => {

        var contents = await getFilterRecord(filter, "content")
    var allSet = true;
        contents.forEach((content) =>    allSet=allSet && data.setLoadedContent(content))
        noSet = noSet || allSet
        if (_data?.live) {
            contents = contents.map(tpl => _data.lives["content_" + tpl.content_id] ?? tpl)
        }
        if (_data?.sets.setContents && !noSet) {
            console.log("refresh")
            if (merge)
                _data.sets.setContents(_contents => arrayMerge(_contents, contents, "content_id",true))
            else
                _data.sets.setContents(contents)
        }
        return contents


    },

    updateContent: async (content) => {
         if(_data?.lives)    _data.lives["content_" + content.content_id] = content



         console.log("!!! updateContent " + content.content_id, content)

        if (_data?.live) data.emit({ content });
         data.setLoadedContent(content)

         if (_data?.sets.setContents ) {
            console.log("!!! setContents updateContent " + content.content_id, content)

                _data.sets.setContents(_contents => arrayMerge(_contents, [content], "content_id",true))
 
        }

        if (_data?.complete["content_" + content.content_id]) {

            delete _data.complete["content_" + content.content_id]
            await data.loadCompleteContent(content)
        }

    },
    save_contents: async (contents,blnShowmodalOk=true) => {


        
        var _contents = await (_data?.project?? retrieveProject()).api("/set_contents", { body: contents });
        for(let _content of _contents){ 
        await setRecord(_content, "content")
        }
if(blnShowmodalOk)
    showModal( "" , { closeDelay:1000 ,darkMode:false ,position: ModalPositions.CENTER ,Content: <FontAwesomeIcon  icon={faCheck}  className=" text-3xl text-green-500 "  /> }  )
        return _contents
    },


    update_content: async (content_id, props,blnShowmodalOk=true,noSet=false,complete=true) => {
        
        var content = await (_data?.project?? retrieveProject()).api("/update_content", { body: {content_id,props} });
if(!content) {

window.alert("Probleme " + JSON.stringify(content))
return

}
        await setRecord(content, "content")
if( _data && !noSet)
{
        if (_data.sets.setContent) _data.sets.setContent({ content});
      

        if (_data.sets.setContents) {

          
        
             _data.sets.setContents(contents => arrayMerge(contents, [content], "content_id",true))
       

        }
        if (complete && _data.sets.setCompleteContents) {
            delete _data.complete["content_" + content.content_id]
            await data.loadCompleteContent(content)
        }
        if (_data.live && content.content_id) data.emit({ content_id: content.content_id, endLive: true });
    }
 if(blnShowmodalOk)   showModal( "" , { closeDelay:1000 ,darkMode:false ,position: ModalPositions.CENTER ,Content: <FontAwesomeIcon  icon={faCheck}  className=" text-3xl text-green-500 "  /> }  )
        return content
    },

    contentToEntity:(content)=>{

var entity = {...content,content:undefined}

      entity[ entity.entity_name+"_id"] = parseInt( content.content_id.split("_")[1])
   const jsonMap={"app":"profile","user":"profile"}

entity[ jsonMap[entity.entity_name] ?? "content"  ] = content.content
      return entity

    }
    ,
    entityToContent:(entity,entity_name)=>{
const schemaMap={"app":"60","user":"61"}
var content = { schema_id :schemaMap[entity_name],   ...entity,entity_name}

 content.content_id =  entity_name+"_" + entity[entity_name+"_id" ]   
  
 const jsonMap={"app":"profile","user":"profile"}
content.content=entity[ jsonMap[entity_name] ?? "content"  ] 

delete  content[entity_name+"_id" ] 
delete content[jsonMap[entity_name]]

        return content
    }
    ,
    save_content: async (content,blnShowmodalOk=true) => {
        
        var _content = await (_data?.project?? retrieveProject()).api("/set_content", { body: content });
     

if(!_content.entity_name)

        await setRecord(    _content, "content")
        else
        await setRecord(  data.contentToEntity(  _content), _content.entity_name  )


 if(_content && _content.entity_name ){

    return _content
 }


      
if( _data)
{
        if (_data.sets.setContent) _data.sets.setContent({ ...content, ..._content });
      

        if (_data.sets.setContents) {

          
        
             _data.sets.setContents(contents => arrayMerge(contents, [_content], "content_id",true))
       

        }
        if (_data.sets.setCompleteContents) {
            delete _data.complete["content_" + content.content_id]
            await data.loadCompleteContent(_content)
        }
        if (_data.live && content.content_id) data.emit({ content_id: content.content_id, endLive: true });
    }
    if(blnShowmodalOk)   showModal( "" , { closeDelay:1000 ,darkMode:false ,position: ModalPositions.CENTER ,Content: <FontAwesomeIcon  icon={faCheck}  className=" text-3xl text-green-500 "  /> }  )
        return _content
    },

    delete_content: async (content_id) => {
         await (_data?.project?? retrieveProject()).api("/delete_content/:content_id",{args:{content_id}})
         
        await deleteRecord(content_id, "content")
        if( _data)
        {    
        if (_data.sets.setContents) _data.sets.setContents(contents => contents.filter(content=> content.content_id!==parseInt(content_id) ))
        if (_data.sets.setCompleteContents) {
            delete _data.complete["content_" + content_id]
        
        }
        if (_data.live && content_id) data.emit({ content_id: content_id, endLive: true });
      }
        return 
    },
    newContent: async (content) => {
        return await data.save_content(content);
    },
    getSchema: async (content_id) => { 

        var content = await getRecord(parseInt(content_id), "content");

        return content

    },

    getSchemas: async (filter, merge, noSet) => {
        var _filter = (content) => { return content.is_schema && (!filter || filter(content)) };

        var contents = await getFilterRecord(_filter, "content")
        console.log("schmas contens", contents)
        contents.sort((a,b)=> a.title <b.title ? -1:1) 
        data.loadedSchemas= contents
        if (_data?.live) {
            contents = contents.map(tpl => _data.lives["content_" + tpl.content_id] ?? tpl)
        }
        if (_data?.sets.setSchemas && !noSet) {
            if (merge)
                _data.sets.setSchemas(_contents => arrayMerge(_contents, contents, "content_id",true))

            else
                _data.sets.setSchemas(contents)
        }
        return contents

    },

    updateSchema: async (schema) => {
        _data.lives["content_" + schema.content_id] = schema


        if (_data.live) data.emit({ schema });



    },
    setSchema: () => { },
    getSchemasByApp: async (app_id, merge, noSet) => {

        var schemas = await data.getSchemas((content) => parseInt(content.app_id) === parseInt(app_id) ||  content.is_public  || !content.app_id, true)
        console.log("SchemasBy App", app_id, schemas)

        if (_data?.sets.setSchemas && !noSet) {
            if (merge)
                _data.sets.setSchemas(_contents => arrayMerge(_contents, schemas, "content_id",true))

            else
                _data.sets.setSchemas(schemas)
        }

        return schemas
    },
    getTemplatesByPage: async (page) => {

    },

    getContentsByPage: async (page) => {

    },

    getSchemasByPage: async (page) => {

    },

    saveAll: async () => {

        var saves =  Object.keys(_data.lives).filter(key=> !_data.lives[key].test )
        for (var i = 0; i < saves.length; i++) {
            var record = saves[i].split("_")[0]
            console.log("record SAVE",_data.lives[saves[i]] )
if( _data.lives[saves[i]].template_id ||  _data.lives[saves[i]].schema_id ||  _data.lives[saves[i]].page_id || 
    ( _data.lives[saves[i]].content_id && (_data.lives[saves[i]].content_id+"").split("_").length===1  ) ) 
    
    await data["save_" + record](_data.lives[saves[i]])

        }

        _data.lives = {}
        if (_data.sets.setSaveAll) _data.sets.setSaveAll(_data.lives)
        if (_data.live) data.emit({ endLive: true });





    },


    updateChange: async (record ,record_id , action, key, value )=>{
        
        // action :  add, set, delete 

    },

     isGranted : (authUser,record,table,action)=>{
     if(   !authUser) return false;
      // if( authUser.user_id ===1 )  return true;
if(authUser.grants){
    if(authUser.grants[table+"_"+action] ) return true
    if(authUser.grants["*_"+action] ) return true
    if(authUser.grants[table+"_*"] ) return true
    if(authUser.grants["*_*"] ) return true

}
  if( record && record.owner_id=== authUser.user_id ) return true; 
        if(action==="duplicate") return true;
return false;
     }
,
     getGlobalData: async function (p) {
        if (data.globalDataLoading) {
          console.log("globalDataLoading already loaded");
          return;
        }
        try{
        console.log("globalDataLoading loading");
        data.globalDataLoading = true;
        p = retrieveProject();
        var timestamp = localStorage.getItem("timestamp");
        var body = {};
        if (timestamp) body.timestamp = timestamp;
        var global = await p.api("/get_global_data", { body });
    
        console.log("Données globales récupérées avec succès");
        if (!timestamp) timestamp = "0000-00-00 00:00:00";
        var tables = Object.keys(global);
    
        for (let i = 0; i < tables.length; i++) {
          let table = tables[i];
          let records = global[table];
          for (let j = 0; j < records.length; j++) {
            if (timestamp < records[j].updated_at)
              timestamp = records[j].updated_at;
    
            if (records[j].active === 0) {
              console.log("delete ", table, records[j]);
    
              await deleteRecord(records[j][table + "_id"], table);
            } else {
              console.log("Add", table, records[j]);
    
              await setRecord(records[j], table);
            }
          }
        }
    
        localStorage.setItem("timestamp", timestamp);
        data.globalDataLoading = false;
        console.log("globalDataLoading terminated");
      }
      catch (err) {
    
        window.alert("Error: " + err.message)
    
      }
      }
}






export { data }