import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChooseImage from "./ChooseImage";
import InputAudio from "./InputAudio";
import InputBoolean from "./InputBoolean";
import InputColor from "./InputColor";
import InputFile from "./InputFile";
import InputIcon from "./InputIcon";
import InputMedia from "./InputMedia";
import InputSelect from "./InputSelect";
import InputText from "./InputText";
import InputTextArea from "./InputTextArea";

import InputVideo from "./InputVideo";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import * as fa from "@fortawesome/free-solid-svg-icons";
import InputDefinition from "./InputDefinition";
import InputSchema from "./InputSchema";
import InputTitreText from "./InputTitreText";
import InputContent from "./InputContent";

import InputDate from "./imputDate/InputDate";
import InputTime from "./InputTime";
import InputDateTime from "./InputDateTime";
import InputPeriodDate from "./InputPeriodDate";
import InputPeriodTime from "./InputPeriodTime";
import InputPeriodTimeScroll from "./InputPeriodTimeScroll";

import InputLink from "./InputLink";
import ContentSelect from "./ContentSelect";
import InputPage from "./InputPage";

import InputPhoneNumber from "./inputPhoneNumber/InputPhoneNumber";

import InputEnumSelect from "./InputEnumSelect";
import InputMap from "./InputMap";
import InputTemplate from "./InputTemplate";
import InputTextCodeEditor from "./InputCodeEditor";
import { useState } from "react";
import InputAction from "./InputAction";
import InputCssCondition from "./InputCssCondition";
import InputFiltre from "./InputFiltre";
import InputDomain from "./InputDomain";
import InputEntity from "./InputEntity";
import { suidize } from "../../service/suid";
import InputMultiSelectContent from "./InputMultiSelectContent";
import InputComputed from "./InputComputed";

import InputContentVariable from "./InputContentVariable";
import InputFiltreEtat from "./InputFiltreEtat";
import ChooseFile from "./ChooseFile";
import InputHtml from "./InputHtml";



const InputData = ({
    id,
    schema,
    prop,
    onChange,
    value,
    className,
    defaultValue,
    options,
    domain,


    placeholder,
    values,
    updateContent,
    context
}) => {


const [showList,setShowList]=useState(context?.showList )
const [showInfos,setShowInfos]=useState()



if (schema  ) context ={...context, schema}
    if( (prop.type_nombre === "I" || prop.type_nombre === "L" || prop.type_nombre === "F") && context?.json) {
        console.log({value,prop})
        value= value ? JSON.parse(value) : null
        value =value ? (Array.isArray(value)? value : [value]) : []
       }
      
       if (Array.isArray(value)  ){ value = suidize(value)}
const [keyValue,setKeyValue]=useState(value?? [])


    if (prop.type_nombre === "I" || prop.type_nombre === "L" || prop.type_nombre === "F") {



        if (prop.code) {
            switch (prop.code) {
                case 'enum':
                    return <InputEnumSelect id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} values={prop.values} infos={prop.infos} />
             
                    case "":
                        return 
                default:
            }
        }
      if(  prop.is_inline){

     return   <InputMultiSelectContent      id={id} label={prop.name} onChange={onChange} value={value} className={className} options={options} schema_id={prop.schema_id} domain={domain}  placeholder={placeholder} values={prop.values} infos={prop.infos}   />
      }
        if(context?.minimized){

            return (<div className="flex  flex-wrap items-center justify-end  px-2">
                {(value ?? []).map((v, i) => (
                    <div className="inline-flex flex-col items-center childContainer  m-1 px-1" key={v?.suid ??  keyValue.findIndex(v0=> JSON.stringify(v0)=== JSON.stringify(v))?? i  }>
                   
     <div className=" flex flex-row items-center  rounded-full bg-slate-100"> 
                        <InputData
                            domain={domain}
                            schema={schema}
                            defaultValue={defaultValue}
                            className={className}
                            context={context}
                            prop={{
                                ...prop,
                                infos:null,
                                name: prop.name + " " + (i + 1),
                                type_nombre: null,
                            }}
                          
                            id={id+"["+i+"]"} 
                            value={v}
    
                            placeholder={placeholder}
                            updateContent={updateContent}
                            onChange={(_value) => {
                                var values = value ?? [];
                                if (_value) values[i] = _value;
                                else values = values.filter((v, j) => j !== i);
    
    
                                var  newvalues =values;
                               
                                onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
                          
                            }}
    
                            options={options}
                            values={value ?? []}
                            
                        />
     <FontAwesomeIcon
                            className="appbutton bg-red-600 p-1 text-xs w-3 h-3 text-white rounded-full" 
                            icon={faClose}
                            onClick={() => {
                                var values = value ?? [];
                               var  newvalues = values.filter((v, j) => j !== i);
                               
                               onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
                            }}
                            
                        />
                        
                    </div>
                    </div>
                ))}
              
                  <FontAwesomeIcon
                className="   bg-green-600 p-2 w-3 h-3 text-white rounded-full ml-2"
                icon={fa.faPlus}
                onClick={() => {
                    var values = value ?? [];
                    var  newvalues =[...values,""];
                           
                    onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
             
                 
                }}
       
            />
              
                {prop.infos &&  <div className="italic w-full text-xs p-1 text-left  whitespace-pre-line">{prop.infos}</div>}
    
            </div>
            )
        }

        return (<div className="flex flex-col w-full containerList">
         { !context?.showList &&  <div className="w-full flex justify-between items-center text-start p-2 font-bold"  onClick={() => {
                          setShowList( !showList )
                        }}>
            <span>Liste { prop.name } ({((value && Array.isArray(value)  ? value :null) ?? []).length })</span>
            <FontAwesomeIcon
                        className="appbutton   bg-gray-600 p-2 text-white rounded-full "
                        icon={showList ? fa.faChevronUp : fa.faChevronDown}
                       
                    /> 
            </div> 
            }
            {((showList && value && Array.isArray(value)  ? value :null) ?? []).map((v, i) => (
                <div className="w-full flex flex-col items-center childContainer py-2 mt-2 " key={ v?.suid  ?? keyValue?.findIndex(v0=> JSON.stringify(v0)=== JSON.stringify(v))?? i   }>
            {   showInfos=== ( v?.suid  ?? keyValue?.findIndex(v0=> JSON.stringify(v0)=== JSON.stringify(v))?? i)  &&    <div className="flex w-full justify-between px-2 "> 

                    <div className="text-left font-bold ">{  prop.name + " " + (i + 1)}  </div>
                    
                    <div className="flex flex-row gap-1 text-xs "> 
                
                { i>0 &&  <FontAwesomeIcon
                        className="appbutton   bg-green-600 p-2 text-white rounded-full "
                        icon={fa.faArrowUp}
                        onClick={() => {
                            var values = value ?? [];
                          var newvalues = [...values.filter((_, j) => j+1 < i  && i!==j),v,...values.filter((_, j) => j+1 >= i  && i!==j)]
                            onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
                        }}
                    />}
                    { i<value.length-1 && <FontAwesomeIcon
                        className="appbutton   bg-green-600 p-2 text-white rounded-full"
                        icon={fa.faArrowDown}
                        onClick={() => {
                            var values = value ?? [];
                    
                            var newvalues=[...values.filter((_, j) => j-1 <= i && i!==j),v,...values.filter((_, j) => j-1 > i  && i!==j)];
                            onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
                        }}
                    />}
                      <FontAwesomeIcon
                        className="appbutton bg-red-600 p-2 text-white rounded-full" 
                        icon={faClose}
                        onClick={() => {
                            var values = value ?? [];
                           var  newvalues = values.filter((v, j) => j !== i);
                           
                           onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
                        }}
                    />
                    </div>
                    </div>}
 <div className="w-full flex flex-row items-start relative"> 
                    <InputData
                        domain={domain}
                        schema={schema}
                        defaultValue={defaultValue}
                        className={className}
                        context={context}
                        prop={{
                            ...prop,
                            infos:null,
                            name: prop.name + " " + (i + 1),
                            type_nombre: null,
                        }}
                      
                        id={id+"["+i+"]"} 
                        value={v}

                        placeholder={placeholder}
                        updateContent={updateContent}
                        onChange={(_value) => {
                            var values = value ?? [];
                            if (_value) values[i] = _value;
                            else values = values.filter((v, j) => j !== i);


                            var  newvalues =values;
                           
                            onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
                      
                        }}

                        options={options}
                        values={value ?? []}
                        
                    />
  <FontAwesomeIcon
                        className="appbutton   text-gray-600 p-2 bg-white  rounded-full absolute right-2 shadow"
                        onClick={()=> {
   var val=  ( v?.suid  ?? keyValue?.findIndex(v0=> JSON.stringify(v0)=== JSON.stringify(v))?? i) 
if (showInfos===val) val=null
setShowInfos(val)

                        }}
                        icon={  showInfos=== ( v?.suid  ?? keyValue?.findIndex(v0=> JSON.stringify(v0)=== JSON.stringify(v))?? i)  ? fa.faChevronUp : fa.faChevronDown}
                       
                    /> 
                </div>
                </div>
            ))}
            { showList && <><div
                className=" font-bold text-green-600 p-2 border-b flex items-center justify-end"
                onClick={() => {
                    var values =   (value && Array.isArray(value)  ? value :null) ?? []
                 
                    var  newvalues =[...values,""];
                           
                    onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
             
                 
                }}
            >
                {" "}
                Ajouter {prop.name}<FontAwesomeIcon
            className="   bg-green-600 p-2 appbutton text-white rounded-full ml-2"
            icon={fa.faPlus}
   
        />
            </div>
            {prop.infos &&  <div className="italic w-full text-xs p-1 text-left  whitespace-pre-line">{prop.infos}</div>}
            </>}

        </div>
        );
    }
    if(schema.content?.entity){
        return <InputEntity id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} schema={schema} options={schema.content.values} infos={prop.infos}  entity={schema.content?.entity } context={context}/>

    } 

    if(prop.option_optionValues && prop.optionValues){
        return   <InputSelect id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder}  options={prop.optionValues} infos={prop.infos} context={context}/>   
    }
    if (schema && schema.content?.code && !context?.noCode) {
        switch (schema.content.code) {
            case 'definition':
                return <InputDefinition id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} definitions={values} infos={prop.infos}    context={context}/>
             
           case 'schema':
                return <InputSchema id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}   context={context}/>
            case 'filtre':
            case "filtre_schema":
                    return <InputFiltre id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos} context={context}/>
                case 'text':
    
                    if(options)
                      return   <InputSelect id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder}  options={options} infos={prop.infos} context={context}/>   
               
                    return <InputText id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}  context={context}/>
                    case 'html':
    
                    return <InputHtml id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}  context={context}/>
             case 'domain':
                    return <InputDomain id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}  context={context}/>
         
                case 'css_condition':
      return <InputCssCondition id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}  context={context}/>
                   case 'js':
           return <InputTextCodeEditor id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}  language={schema.content.code}    context={context}/>
            case 'map':
            case 'geohash':
                return <InputMap id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}   context={context}/>
            case 'phonenumber':
                return <InputPhoneNumber id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}   context={context}/>
            case 'titre_text':
                return <InputTitreText id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}   context={context}/>
            case 'long text':
                return <InputTextArea id={id} label={prop.name} onChange={onChange} value={value} className={className} infos={prop.infos}   context={context}/>
                case 'variable_content':
                    return <InputContentVariable id={id} label={prop.name} onChange={onChange} value={value} className={className} infos={prop.infos}   context={context}/>
                    case 'filtre_etat':
                        return <InputFiltreEtat id={id} label={prop.name} onChange={onChange} value={value} className={className} infos={prop.infos}   context={context}/>

             
            case 'numeric':
                return <InputText id={id} label={prop.name} onChange={onChange} value={value} className={className} infos={prop.infos}   context={context}/>
            case 'date':
                return <InputDate id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}   context={context}/>
            case 'perioddate':
                return <InputPeriodDate id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}   context={context}/>
            case 'time':
                return <InputTime id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}   context={context}/>

            case 'periodtime':
                return <InputPeriodTimeScroll id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}   context={context}/>
            //    return <InputPeriodTime id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} />
            case 'datetime':
                return <InputDateTime id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder} infos={prop.infos}   context={context}/>
            case 'icon':
            case 'icone':
                return <InputIcon id={id} label={prop.name} onChange={onChange} value={value} className={className} infos={prop.infos}   context={context}/>
            case 'color':
                return <InputColor id={id} label={prop.name} onChange={onChange} value={value} className={className} infos={prop.infos}   context={context}/>
            case 'boolean':
                return <InputBoolean id={id} labelTrue={prop.name} onChange={onChange} value={value} className={className} infos={prop.infos}   context={context}/>
            case 'media':
                return <InputMedia id={id} label={prop.name} onChange={onChange} value={value} domain={domain} infos={prop.infos}   context={context}/>
            case 'video':
            case 'video-stream':
                return <InputVideo id={id} label={prop.name} onChange={onChange} value={value} domain={domain} infos={prop.infos}   context={context}/>
            case 'audio':
            case 'audio-stream':
                return <InputAudio id={id} label={prop.name} onChange={onChange} value={value} domain={domain} infos={prop.infos}   context={context}/>

            case 'file':
                return <ChooseFile id={id} label={prop.name} onChange={onChange} value={value} domain={domain} infos={prop.infos}   context={context}/>
case "ihm_action": 
return <InputAction id={id} label={prop.name} onChange={onChange} value={value} domain={domain} infos={prop.infos}   context={context}/>
case "computed": 
return <InputComputed  id={id} label={prop.name} onChange={onChange} value={value} domain={domain} infos={prop.infos}   context={context}/>
            case "link":
                return (
                    <InputLink
                        id={id}
                        label={prop.name}
                        onChange={onChange}
                        value={value}
                        className={className}
                        placeholder={placeholder}
                        domain={domain}
                        context={context}
                    />
                );


            case "page":
                return (
                    <>

                        <InputPage
                            id={id}
                            label={prop.name}
                            onChange={onChange}
                            value={value}
                            domain={domain}
                            placeholder={placeholder}
                            filter={prop.filter}
                            context={context}
                        />
                    </>
                );
                case "template":
                    return (
                        <>
    
                            <InputTemplate
                                id={id}
                                label={prop.name}
                                onChange={onChange}
                                value={value}
                                domain={domain}
                                placeholder={placeholder}
                                filter={prop.filter}
                                context={context}
                            />
                        </>
                    );
    
            case "image":
            case "image-app":
            case "image-user":

                return <ChooseImage id={id} label={prop.name} defaultValue={defaultValue} placeholder={"Choisir une image"} onChange={onChange} value={value} domain={domain} infos={prop.infos}    context={context}/>
            case "action":
                return <></>





            default:
                return (
                    <InputText
                        label={prop.name}
                        onChange={onChange}
                        value={value}
                        className={className}
                        infos={prop.infos}
                        context={context}
                    />
                );
        }
    }
  
    
    else if (schema.content?.is_enum){
        return <InputSelect id={id} label={prop.name} onChange={onChange} value={value} className={className} placeholder={placeholder}  options={schema.content.values} infos={prop.infos}    context={context}/>

    }
    else if (prop.define) {
        return (    
            // Sous-Contenu
        <>
      
        <InputContent
            id={id}
                schema={schema}
                label={prop.name ?? schema.title}
                onChange={onChange}
                value={value}
                className={className}
                domain={domain}
                opened ={prop.define_open || !prop.tab}
                infos={prop.infos}
                context={context}
            />
        </>
        );
    }



    if (prop.isSpe)
        return (
            <InputSelect
                label={prop.name ?? schema.title}
                onChange={onChange}
                value={value}
                className={className}
                placeholder={placeholder}
                options={options}
                infos={prop.infos}
                context={context}
            />
        );
    else
        return (
            <ContentSelect
                template_id={prop.template_id}
                label={prop.name ?? schema.title}
                onChange={(content_id) =>
                    onChange({ content_id: parseInt(content_id) })
                }
                value={value?.content_id ?? value}
                className={className}
                placeholder={placeholder}
                options={options?.map((c) => {
                    return { value: c.content_id, label: c.title };
                })}
                updateContent={updateContent}
                domain={domain}
                schema_id={schema.content_id}
                infos={prop.infos}
                context={{...context,prop}}
            />
           
        );

}
export default InputData;
