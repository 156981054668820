import React, { useEffect, useMemo, useState, useContext, useRef } from "react";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TemplateDisplay from "../../../../components/templates/TemplateDisplay";

import Image from "../../../../components/Image";
import "../../../../App.css";
import { MfctContext } from "../../../../context";
import { getAllRecords } from "../../../../service/localdata";
import { data } from "../../../../service/data";
import Header1 from "../../../../components/Header1";
import UserThumb from "../../../../components/thumbs/UserThumb";
import UserPin from "../../../../components/UserPin";
import ButtonTool from "../../../../components/ButtonTool";
import VarContent from "../../../../components/VarContent";
import { Icon } from "leaflet";
import InputSelect from "../../../../components/inputs/InputSelect";
import InputPropSchema from "../../../../components/inputs/InputPropSchema";
import InputForSchemaProp from "../../../../components/inputs/InputForSchemaProp";
import {
  showModalError,
  showModalLoading,
  showModalOK,
} from "../../../../components/modal";
import LoaderSmall from "../../../../components/LoaderSmall";

function Deployments() {

  const location = useLocation();

  const [APP_ID, setAppId] = useState();
  const [SERVER_ACCOUNT, setServerAccount] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const _APP_ID = queryParams.get("app_id")
      ? parseInt(queryParams.get("app_id"))
      : null;
    const _SERVER_ACCOUNT = queryParams.get("server_account");

    setAppId(_APP_ID);
    setServerAccount(_SERVER_ACCOUNT);
    setFilterDeploy({
      deployment_id: "filter",
      app_id: _APP_ID,
      server_account: _SERVER_ACCOUNT,
    });
  }, [location]);

  const DEPLOYMENT_SCHEMA_ID = 471;
  const DEPLOYMENT_PROPS_ = [
    {prop:"app_id",size:3},
     {prop:"client_id",size:3},
     {prop:
    "server_account",size:1},
    {prop:
    "nature",size:1},
    {prop:
    "status",size:1},
    {prop:
    "branch",size:0.5},
    {prop:
    "domain",size:0.5},
    {prop:
    "bdd",size:0.5},
    {prop:
    "addresses",size:4},
    
  ];
  const DEPLOYMENT_PROPS = [
    "app_id",
    "client_id",
    "server_account",
    "nature",
    "status",
    "branch",
    "domain",
    "bdd",
    "addresses",
  ];
  const DEPLOYMENT_PROPS_NAME = [
    "Application",
    "Client",
    "Account",
    "Nature",
    "Status",
    "Branche",
    "Domain",
    "Bdd",
    "Addresses",
  ];
  const NATURE_OPTIONS = [
    "",
    "DRAFT",
    "TEST",
    "DEBUG",
    "RECETTE",
    "PRODUCTION",
  ];
  const STATUS_OPTIONS = [
    "",
    "MAINTENANCE",
    "SETTINGS",
    "BUILDING...",
    "INACTIF",
    "ACTIF",
  ];
  const classTypes = {
    error: "text-red-500 font-bold",
    warning: "text-yellow-500",
  };
  const navigate = useNavigate();

  const { project, getProject, setAuthUser, authUser } =
    useContext(MfctContext);

  const [branches, setBranches] = useState([]);
  const [update, setUpdate] = useState([]);
  const [deployments, setDeployments] = useState([]);
  const [filterDeploy, setFilterDeploy] = useState({
    deployment_id: "filter",
  });
  const [sortDeploy, setSortDeploy] = useState({});
  const [ssh_users, setSshUsers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [products, setProducts] = useState([]);
  const [showAccount, setShowAccount] = useState();
  const [showNewSite, setShowNewSite] = useState();
  const [newAccountName, setNewAccountName] = useState();

  async function getDeployments() {
    const _result = await project.api("/get_deployments", { body: {} });
    setDeployments(_result?? []);
  }
  const setDeployment = (deployment, props, preventModify) => {
    setDeployments((deployments) =>
      deployments.map((a) =>
        a.deployment_id === deployment.deployment_id
          ? {
              ...a,
              ...props,
              modified: preventModify ? deployment.modified : true,
            }
          : a
      )
    );
  };
  const cancelDeployment = async (deployment) => {
    setDeployments((deployments) =>
      deployments.map((a) =>
        a.deployment_id === deployment.deployment_id
          ? { ...deployment, cancelled: true }
          : a
      )
    );
    deployment = await project.api("/get_deployment/:deployment_id", {
      args: { deployment_id: deployment.deployment_id },
    });
    setDeployments((deployments) =>
      deployments.map((a) =>
        a.deployment_id === deployment.deployment_id ? deployment : a
      )
    );
  };

  const installDeployment = async (deployment, withPull,blnUpdate) => {
    if (
      !window.confirm(
        `Are you sure you want to ${ blnUpdate ?  "update": "deploy" } app on ${deployment.server_account}?`
      )
    ) {
      return;
    }
    // e.preventDefault();

    const ReceiveMessage = (strMsgs) => {
      const sep = "@*_*@";
      var msgsLines = [];
      var parts = strMsgs.split(sep);
      parts.shift();
      var newmsgs = parts.map((strMsg) => {
        try {
          return JSON.parse(strMsg);
        } catch (error) {
          return { text: strMsg + JSON.stringify(error) };
        }
      });
      newmsgs.forEach((message) => {
        var lines = message.text.split("Line ");
        if (lines.length === 1) {
          if (message.text === "Build terminé" || message.text === "FIN") {
            //    setMods(mods=>({...mods,profile:{...mods.profile,installed_server:mods.profile.deployment_server }}))

            console.log("Fini");
            project
              .api("/get_deployment/:deployment_id", {
                args: { deployment_id: deployment.deployment_id },
              })
              .then((deployment) => {
                setDeployments((deployments) =>
                  deployments.map((a) =>
                    a.deployment_id === deployment.deployment_id
                      ? { ...a, ...deployment, isLoading: null }
                      : a
                  )
                );
                showModalOK();
              });
          }
          msgsLines.push(message);
        } else
          msgsLines = [
            ...msgsLines,
            ...lines.map((line) => {
              return { text: line, type: message.type };
            }),
          ];
      });

      setDeployments((deployments) =>
        deployments.map((a) =>
          a.deployment_id === deployment.deployment_id
            ? { ...a, Result: [...a.Result, ...msgsLines], isLoading: true }
            : a
        )
      );

      setTimeout(() => {
        var obj = document.getElementById(
          "Result_" + deployment.deployment_id + "infos_last"
        );
        if (obj)
          obj.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
      }, 100);
    };

    setDeployment(
      deployment,
      { showInfos: true, Result: [], isLoading: true },
      true
    );
    // TODO : sauvegarder app.profile.deployment_server ; si app.profile.deployment_server existe on le migre vers le nouveau

    try {
      await project.api(    blnUpdate ?"/update_deployment" :  "/install_deployment", {
        body: { deployment_id: deployment.deployment_id, withPull },

        eventStreamListener: ReceiveMessage,
        // project:mods.profile.deployment_server,
        url_server:
          deployment.server_account === "LOCALHOST"
            ? `https://localhost:3030/api/v1/`
            : `https://${deployment.server_account}.appisyou.fr/api/v1/`,
      });
    } catch (err) {
      showModalError(err);
    }
  };


  const uninstallDeployment = async (deployment) => {
    if (
      !window.confirm(
        `Are you sure you want to remove the  app of ${deployment.server_account}?`
      )
    ) {
      return;
    }

    setDeployment(deployment, { isLoading: true }, true);
    try {
      await project.api("/uninstall_deployment", {
        body: { deployment_id: deployment.deployment_id },

        // project:mods.profile.deployment_server,
        url_server:
          deployment.server_account === "LOCALHOST"
            ? `https://localhost:3030/api/v1/`
            : `https://${deployment.server_account}.appisyou.fr/api/v1/`,
        // url_server: `https://localhost:3030/api/v1/`,
      });
    } catch (err) {
      showModalError(err);
    }

    deployment = await project.api("/get_deployment/:deployment_id", {
      args: { deployment_id: deployment.deployment_id },
    });
    setDeployments((deployments) =>
      deployments.map((a) =>
        a.deployment_id === deployment.deployment_id ? deployment : a
      )
    );

    showModalOK();
  };

  const checkDeployment = async (deployment) => {
    window.alert("Vérification à coder...");
  };
  const saveDeployment = async (deployment) => {
    setDeployment(deployment, { isLoading: true }, true);
    deployment = await project.api("/set_deployment", { body: deployment });
    setDeployments((deployments) =>
      deployments.map((a) =>
        a.deployment_id === deployment.deployment_id
          ? { ...deployment, isLoading: null, modified: null }
          : a
      )
    );
  };
  const createDeployment = async (deployment={}) => {
    deployment = await project.api("/set_deployment", {
      body: { ...deployment, deployment_id: null },
    });
    setDeployments((deployments) => [...deployments, deployment]);
  };
  const duplicateDeployment = async (deployment) => {
    setDeployment(deployment, { isLoading: true }, true);
    var newDeployment = await project.api(
      "/duplicate_deployment/:deployment_id",
      {
        args: { deployment_id: deployment.deployment_id },
      }
    );
    setDeployments((deployments) => [...deployments, newDeployment]);
    setDeployment(deployment, { isLoading: false }, true);
  };
  const deleteDeployment = async (deployment) => {
    await project.api("/delete_deployment/:deployment_id", {
      args: { deployment_id: deployment.deployment_id },
    });
    setDeployments((deployments) =>
      deployments.filter((d) => d.deployment_id !== deployment.deployment_id)
    );
  };

  async function getAccounts() {
    try {
      const _result = await project.api("/always/accounts");
      setProducts(_result.products);
      let accountsWithSites = [];

      for (let account of _result.accounts) {
        try {
          let sites = await project.api("/always/account/:account/sites", {
            args: { account: account.name },
          });
          // Filter accounts to only include those with at least one site having working_directory === "backend"
          sites = sites.filter((site) => site.working_directory === "backend");
          var infos_git = {};
          if (sites.length > 0) {
            account = { ...account, backend: sites[0] };

            try {
              const result = await project.api(`/github/github`, {
                body: { request: "get_branch", account: account.name },
              });

              if (result) {
                infos_git = { ...infos_git, ...result };
              }
            } catch (err) {
              console.log(err);
            }
            try {
              const remoteCommitsResult = await project.api(`/github/github`, {
                body: {
                  request: "check_remote_commits",
                  account: account.name,
                },
              });
              if (remoteCommitsResult) {
                infos_git = { ...infos_git, ...remoteCommitsResult };
              }
            } catch (err) {
              console.log(
                `Erreur lors de la récupération des remoteCommits pour ${account.name}:`,
                err
              );
            }
            console.log({ account, infos_git });
            accountsWithSites.push({ ...account, ...infos_git });
          }
        } catch (err) {
          console.log(err);
        }
      }
      setAccounts(accountsWithSites);

      //récupère le nom du dernier account
      let lastAccountName = accountsWithSites[accounts.length - 1].name;
      if (lastAccountName === "prod1") setNewAccountName("aiyprod2");
      else {
        let parts = lastAccountName.split("aiyprod");
        let lastAccountNumber = parseInt(parts[1]);

        // Incrémenter le chiffre et créer le nouveau nom de compte
        let newAccountNumber = lastAccountNumber + 1;
        setNewAccountName(`aiyprod${newAccountNumber}`);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  }
  /**************************************************************************************************************************************************/
  /*                                                  GITHUB                                                                                       */
  /**************************************************************************************************************************************************/

  async function getGitBranches() {
    try {
      var _result = await project.api(`/github/github`, {
        body: { request: "get_branches_front" },
        cached: true,
      });
      if (_result) setBranches(_result.branches);
    } catch (error) {}
  }

  const runAction = async (deployment, action) => {
    document.getElementById(deployment.deployment_id + "_actions").value = "";

    if (deployment.deployment_id === "filter") {
      switch (action) {
        case "Créer":
          await createDeployment(deployment);
          break;
        default:
          for (let _deployment of deployments) {
            if (_deployment.modified || _deployment.selected) {
              await runAction(_deployment, action);
            }
          }
      }
      return;
    }

    switch (action) {
      case "Enregistrer":
        await saveDeployment(deployment);
        break;
      case "Annuler":
        await cancelDeployment(deployment);
        break;
      case "Dupliquer":
        await duplicateDeployment(deployment);
        break;
      case "Installer":
        await installDeployment(deployment);
        break;
        case "Mettre à jour":
          await installDeployment(deployment,null,true);
          break;
      case "Désinstaller":
        await uninstallDeployment(deployment);
        break;
      case "Vérifier":
        await checkDeployment(deployment);
        break;
      case "Supprimer":
        await deleteDeployment(deployment);
        break;

      default:
        return;
    }
  };

  const deploymentValue = (deployment, propName) => {
    switch (propName) {
      case "addresses":
        if (deployment.server_account === "LOCALHOST")
          return deployment.content?.domain
            ? `["localhost:3030/?domain=${deployment.content?.domain}"]`
            : null;
        break;
      case "domain":
        if (!deployment.domain && deployment.deployment_id !== "filter")
          return "ploy" + deployment.deployment_id;
        break;
      case "app_id":

      case "client_id":

      case "server_account":

      case "status":

      case "nature":

      case "bdd":

      case "branch":

      default:
    }
    return deployment[propName];
  };

  const getOptions = (deployment, propName) => {
    if (deployment["status"] === "ACTIF") {
      if (deployment[propName]) {
        return [deployment[propName]];
      }
    }
    switch (propName) {
      case "app_id":
        if (APP_ID) return [APP_ID];
        return;
      case "domain":
        var domains = ["$APP"];
        if (deployment.deployment_id === "filter") domains = [""];
        if (deployment.deployment_id !== "filter")
          domains.push("ploy" + deployment.deployment_id);
        deployments.forEach((d) => {
          if (
            deployment.deployment_id === "filter" ||
            (d.app_id === deployment.app_id &&
              d.deployment_id !== deployment.deployment_id)
          )
            domains.push("ploy" + d.deployment_id);
        });
        return domains;
      case "bdd":
        var bdds = ["", "ploy" + deployment.deployment_id, "$APP"];

        deployments.forEach((d) => {
          if (
            d.app_id === deployment.app_id &&
            d.deployment_id !== deployment.deployment_id
          )
            bdds.push("ploy" + d.deployment_id);
        });
        return bdds;
      case "client_id":
        return;

      case "server_account":
        if (SERVER_ACCOUNT) return [SERVER_ACCOUNT];
        if (accounts) 
          return ["", ...accounts.map((account) => account.name)].filter((name)=> name!=="LOCALHOST" || ( deployment.deployment_id=== "filter" ||  deployment.owner_id +""==="" +authUser.user_id  ));
        
      case "status":
        return STATUS_OPTIONS;

      case "nature":
        return NATURE_OPTIONS;

      case "addresses":
        return;

      case "branch":
        return ["", ...branches];

      case "actions":
        if (deployment.deployment_id === "filter") {
          let actions = [];
          if (deployments.find((d) => d.modified)) {
            actions = [...actions, "Enregistrer", "Annuler"];
          } else if (deployments.find((d) => d.selected)) {
            actions = [...actions, "Vérifier", "Installer", "Mettre à jour"];
          } else actions = [...actions, "Créer"];

          return actions;
        } else {
          let actions = [];
          if (deployment.modified) {
            actions = [...actions, "Enregistrer", "Annuler"];
            return actions;
          }

          actions = [...actions, "Vérifier", "Dupliquer"];
          if (deployment.content.installedAccount)
            actions = [...actions, "Mettre à jour", "Désinstaller"];
          if (!deployment.content.installedAccount)
            actions = [...actions, "Installer", "Supprimer"];
          return actions;
        }
      default:
        return null;
    }
  };

  const deployments_ = useMemo(() => {
    var result = [];
    console.log(sortDeploy);

    (deployments ?? [])
      .filter((d) => {
        for (let key of Object.keys(filterDeploy)) {
          if (key === "deployment_id") continue;

          if (filterDeploy[key] && filterDeploy[key] !== d[key]) return false;
        }

        return true;
      })
      .forEach((deployment) => {
        if (deployment.cancelled) return;

        result.push(deployment);
        if (deployment.showInfos)
          result.push({
            ...deployment,
            deployment_id: deployment.deployment_id + "infos",
            isInfos: true,
          });
      });

    var fields = Object.keys(sortDeploy);

    result.sort((a, b) => {
      for (let field of fields) {
        if (a[field] > b[field]) return sortDeploy[field] === "a" ? 1 : -1;
        if (a[field] < b[field]) return sortDeploy[field] === "a" ? -1 : 1;
      }

      return a.updated_at > b.updated_at ? -1 : 1;
    });
    return result;
  }, [deployments, filterDeploy, sortDeploy]);

  const toggleSort = (field) => {
    setSortDeploy((prevSortDeploy) => {
      if (prevSortDeploy[field]) {
        switch (prevSortDeploy[field]) {
          case "a":
            prevSortDeploy[field] = "d";
            break;
          case "d":
            delete prevSortDeploy[field];
            break;
          default:
            break;
        }
      } else {
        prevSortDeploy[field] = "a";
      }
      // window.alert(JSON.stringify(prevSortDeploy));
      return { ...prevSortDeploy };
    });
  };

  const domainVerif = (deployment) => {
    var domain = deployment.domain ?? "ploy" + deployment.deployment_id;

    for (let _deployment of deployments) {
      if (
        (_deployment.server_account === "LOCALHOST" &&
          deployment.server_account !== "LOCALHOST") ||
        (_deployment.server_account !== "LOCALHOST" &&
          deployment.server_account === "LOCALHOST")
      )
        continue;
      var _domain = _deployment.domain ?? "ploy" + _deployment.deployment_id;
      if (
        _deployment.deployment_id !== deployment.deployment_id &&
        _domain === domain
      )
        return false;
    }
    return true;
  };

  const checkFonctions = [domainVerif];

  const globalCheck = (deployment) => {
    for (let fonction of checkFonctions) {
      if (!fonction(deployment)) return false;
    }
    return true;
  };

  useEffect(() => {
    if (project) {
      document.title = "Déploiements";
      getAccounts();
      getDeployments();
      getGitBranches();
    }
  }, [project]);

  return (
    <div className="safearea App">
      <Header1 title={"Déploiements"} linkLeft={"/"}>
        {!APP_ID && !SERVER_ACCOUNT && (
          <div className="flex flex-col w-full  my-1  mx-4 ">
            <input
              type="text"
              id={"searchBar"}
              className="w-full px-4  rounded-3xl text-zinc-700 p-2"
            />

            <div className=" flex items-center justify-start w-full m-1">
              <div className="grow flex items-end justify-end"></div>
            </div>
          </div>
        )}
        {(APP_ID || SERVER_ACCOUNT) && (
          <div className=" p-2 pl-4 appbutton bg-white text-black flex flex-row items-center  mb-4  mx-4 text-xl rounded-xl">
            <Link to={`/deployments`}> Tous les déploiements </Link>
          </div>
        )}
        {APP_ID && typeof APP_ID === "number" && APP_ID > 0 && (
          <div className=" p-2 bg-white text-black flex flex-row w-full items-center  mb-4  mx-4 text-xl rounded-xl">
            <Link to={`/apps/${APP_ID}/Pages`}>
              {" "}
              <FontAwesomeIcon icon={fa.faChevronLeft} className="mx-4" />{" "}
            </Link>
            <FontAwesomeIcon icon={fa.faMobile} className="mx-2" />
            Application
            <InputForSchemaProp
              id={"open"}
              className={"mx-2"}
              name={"app_id"}
              schema_id={DEPLOYMENT_SCHEMA_ID}
              value={APP_ID}
              onChange={(value) => navigate(`/deployments?app_id=${value}`)}
              options={getOptions({ deployment_id: "all" }, "app_id")}
              context={{ minimized: true, json: true }}
            />
          </div>
        )}

        {SERVER_ACCOUNT && (
          <div className=" p-2 bg-white text-black flex flex-row w-full items-center  mb-4  mx-4 text-xl rounded-xl">
            <Link to={`/hostings?server_account=${SERVER_ACCOUNT}`}>
              {" "}
              <FontAwesomeIcon icon={fa.faChevronLeft} className="mx-4" />{" "}
            </Link>
            <FontAwesomeIcon icon={fa.faHouse} className="mx-2" />
            Hébergement
            <InputForSchemaProp
              id={"open"}
              className={"mx-2"}
              name={"server_account"}
              schema_id={DEPLOYMENT_SCHEMA_ID}
              value={SERVER_ACCOUNT}
              onChange={(value) =>
                navigate(`/deployments?server_account${value}`)
              }
              options={getOptions({ deployment_id: "all" }, "server_account")}
              context={{ minimized: true, json: true }}
            />
          </div>
        )}
      </Header1>

      <div className="min-w-full     md:grid "  style={{
        
         //  gridTemplateColumns:`repeat(${3+DEPLOYMENT_PROPS_NAME.length},auto)`
           gridTemplateColumns:`1fr  ${DEPLOYMENT_PROPS_.map(e=>e.size+"fr").join(" ")} 2fr 0.5fr`  ,
       // gridTemplateColumns:`repeat(${3+DEPLOYMENT_PROPS_NAME.length}, minmax(0, 1fr))`
        }} >
       
         
            <div className="py-2  bg-slate-200 hidden md:block">ID </div>
            {DEPLOYMENT_PROPS_NAME.map((name, i) => {
              return (
                <div
                  className="  hidden md:block"
                  onClick={() => toggleSort(DEPLOYMENT_PROPS[i])}
                >
                  <div className="py-2  bg-slate-200  flex items-center justify-center">
                    {name}
                    {sortDeploy[DEPLOYMENT_PROPS[i]] && (
                      <FontAwesomeIcon
                        className=" w-4 h-4 rounded-full m-2 shadow"
                        icon={
                          sortDeploy[DEPLOYMENT_PROPS[i]] === "a"
                            ? fa.faSortAmountAsc
                            : fa.faSortAmountDesc
                        }
                      />
                    )}
                  </div>
                </div>
              );
            })}

            <div className="py-2  hidden md:block  bg-slate-200">Actions</div>
            <div className="py-2   hidden md:block bg-slate-200"></div>
     

         
            {
              <>
                <div className="  hidden md:block bg-white py-2 text-center">
                  {" "}
                  <FontAwesomeIcon
                    className=" w-4 h-4 rounded-full m-2 shadow"
                    icon={fa.faFilter}
                  />{" "}
                </div>
                {DEPLOYMENT_PROPS.map((propName) => (
                  <div className="  hidden md:block bg-white py-2  text-left  align-top ">
                    <InputForSchemaProp
                      id={"filter_" + propName}
                      name={propName}
                      schema_id={DEPLOYMENT_SCHEMA_ID}
                      value={filterDeploy[propName]}
                      onChange={(value) =>
                        setFilterDeploy((filterDeploy) => ({
                          ...filterDeploy,
                          [propName]: value,
                        }))
                      }
                      options={getOptions(filterDeploy, propName)}
                      context={{ minimized: true, json: true }}
                    />{" "}
                  </div>
                ))}
                <div className="py-2 hidden md:block bg-white ">
                  <InputSelect
                    id={"filter_actions"}
                    className={
                      "" +
                      (deployments.find((d) => d.modified)
                        ? "bg-orange-200"
                        : "")
                    }
                    label={"Action globale"}
                    onChange={(action) => runAction(filterDeploy, action)}
                    options={getOptions(filterDeploy, "actions")}
                    placeholder={"Action globale"}
                    value={""}
                  ></InputSelect>
                </div>
                <div className="hidden md:block bg-white py-2 ">
                  <FontAwesomeIcon
                    className=" w-4 h-4 rounded-full m-2 shadow"
                    icon={fa.faClose}
                    onClick={() =>
                      setFilterDeploy({
                        deployment_id: "filter",
                        app_id: APP_ID,
                        server_account: SERVER_ACCOUNT,
                      })
                    }
                  />
                </div>
              </>
            }
      

          {deployments_.length > 0 ? (
            deployments_.map((deployment,iDeploy) => (
              <div
                className={
                  " bg-white p-2 rounded-md m-2 shadow-md md:grid border-gray-400 " + (deployment.isInfos ? "" : "border-t")
                }

                style={
                  {
                    gridTemplateColumns:`1fr  ${DEPLOYMENT_PROPS_.map(e=>e.size+"fr").join(" ")} 2fr 0.5fr`  ,
                   // gridTemplateColumns:`repeat(${3+DEPLOYMENT_PROPS_NAME.length}, auto)`,
                gridColumn: `span ${3+DEPLOYMENT_PROPS_NAME.length} / span ${3+DEPLOYMENT_PROPS_NAME.length}`
              
              
              }}
                key={deployment.deployment_id}
              >
                {   deployment.isInfos ? (
                  <div  className="p-2 text-left text-xs w-full">
                    {/* Contenu de la nouvelle ligne */}
                    Infos
                   
                    {deployment.Result && deployment.Result.length > 0 && (
                      <div
                        className="flex flex-col w-full border border-slate-500 rounded-xl  overflow-y-auto p-2"
                        style={{ maxHeight: "50vh" }}
                        id={"Result_" + deployment.deployment_id}
                      >
                        Résultat :
                        {deployment.Result.map((message, i) => (
                          <div
                            id={
                              "Result_" +
                              deployment.deployment_id +
                              "_" +
                              (i === deployment.Result.length - 1 ? "last" : i)
                            }
                            className={
                              "w-full p-1 text-xs text-left " +
                              (message.type && classTypes[message.type])
                            }
                            key={i}
                          >
                            {message.text}
                          </div>
                        ))}
                      </div>
                    )}
                     <VarContent data={{ ...deployment, Result: null }} />
                  </div>
                ) : (
                  <>
                    <div className="w-full p-2 text-right  bg-slate-200  md:bg-transparent" 
               
                    >
                      <div className="flex ">
                        {deployment.deployment_id}{" "}
                        {deployment.app_id && (
                          <Link to={`/apps/${deployment.app_id}/Pages`}>
                            <FontAwesomeIcon
                              icon={fa.faMobile}
                              className="mx-2"
                            />
                          </Link>
                        )}
                        {deployment.server_account && (
                          <Link
                            to={`/hostings?server_account=${deployment.server_account}`}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={fa.faHouse}
                              className="mx-2"
                            />
                          </Link>
                        )}
                      </div>
                    </div>

                    {DEPLOYMENT_PROPS.map((propName) => (
                      <div className="py-2  text-left  align-top ">
                        <InputForSchemaProp
                          id={deployment.deployment_id + "_" + propName}
                          key={deployment.deployment_id + "_" + propName}
                          name={propName}
                          className={
                            (propName === "status" &&
                            deploymentValue(deployment, propName) === "ACTIF"
                              ? " bg-green-500"
                              : "") +
                            (propName === "status" &&
                            deploymentValue(deployment, propName) === "INACTIF"
                              ? " bg-red-500"
                              : "") +
                            (propName === "status" &&
                            deploymentValue(deployment, propName) ===
                              "MAINTENANCE"
                              ? " bg-orange-500"
                              : "") +
                            (propName === "status" &&
                            deploymentValue(deployment, propName) === "READY"
                              ? " bg-blue-500"
                              : "") +
                            (propName === "domain" && !domainVerif(deployment)
                              ? " bg-red-500"
                              : "")
                          }
                          schema_id={DEPLOYMENT_SCHEMA_ID}
                          value={deploymentValue(deployment, propName)}
                          onChange={(value) =>
                            setDeployment(deployment, { [propName]: value })
                          }
                          options={getOptions(deployment, propName)}
                          context={{ minimized: true, json: true }}
                        />{" "}
                      </div>
                    ))}
                    <div className="py-2  ">
                      {deployment.isLoading ? (
                        <LoaderSmall className="w-8 h-8" />
                      ) : deployment.modified && !globalCheck(deployment) ? (
                        <InputSelect
                          className={"bg-red-200"}
                          label={"Action requise !"}
                          onChange={(action) => {}}
                          options={[]}
                          placeholder={"Action requise !"}
                          value={""}
                        ></InputSelect>
                      ) : (
                        <InputSelect
                          id={deployment.deployment_id + "_actions"}
                          className={
                            "" + (deployment.modified ? "bg-orange-200" : "")
                          }
                          label={"Choisir une action"}
                          onChange={(action) => runAction(deployment, action)}
                          options={getOptions(deployment, "actions")}
                          placeholder={"Choisir une action"}
                          value={""}
                        ></InputSelect>
                      )}
                    </div>
                    <div className="py-2 ">
                      <FontAwesomeIcon
                        className=" w-4 h-4 rounded-full m-2 shadow"
                        icon={fa.faEllipsisVertical}
                        onClick={() =>
                          setDeployment(
                            deployment,
                            { showInfos: !deployment.showInfos },
                            true
                          )
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            ))
          ) : (
            <div className="w-full py-2 text-center col-span-full">
           
                No deployments
            
            </div>
          )}
     
      </div>
    </div>
  );
}

export default Deployments;

//ajouter les fonctionnalités (penser à RM, git stash, ect...)
//appisyou_prod* regarder dernier serveur de la liste et rajouter +1
//mdp généré envoyé par mail aux admins (chercher les admins)
//mdp => configs des comptes sur appisyou/backend dossier accounts, un fichier json par account avec le résultat du getaccount de alwaysdata + mdp , nom du fichier prod*
