import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useRef, useState } from "react";
import { getStructure } from "../../../../../tools/tpl";
import InputTextArea from "../../../../../components/inputs/InputTextArea";

const availableClasses = [

];
availableClasses.sort((a, b) => (a < b ? -1 : 1));




function getTailwindStyles(classNames) {
  const tempDiv = document.createElement("div");
  tempDiv.className = classNames;
  document.body.appendChild(tempDiv);

  const styles = window.getComputedStyle(tempDiv);
  const styleRules = Array.from(styles)
    .map((prop) => `${prop}: ${styles.getPropertyValue(prop)};`)
    .join("\n");

  document.body.removeChild(tempDiv);
  if(document.getElementById("inputTool0Style")) document.getElementById("inputTool0Style").value= styleRules;
}

const Tool0_Style = ({ updateStructure, structure, idObj, selectTpl }) => {

  const textStyle=useRef()
  const [show, setShow] = useState(true);

  
  const classes = useMemo(() => {
 
    var _structure = getStructure(structure, idObj);

    var parts = _structure.className
      .split(" ")
      .filter((classe) => classe && classe !== "");

    return parts;
  }, [structure, idObj]);
  const mediaClasses = useMemo(() => {
 
    var _structure = getStructure(structure, idObj);

    return _structure.mediaClasses ?? []
 
  }, [structure, idObj]);
  


  const cssAttributes = useMemo(() => {
 
    var _structure = getStructure(structure, idObj);

    var parts =   Object.keys( _structure.cssAttributes ?? {}).map((key) => key + ':' + _structure.cssAttributes[key])

    return parts;
  }, [structure, idObj]);

  const removeCssAttribute = (keyvalue) => {

    var cssAttributes = getStructure(structure, idObj).cssAttributes;
    var key = keyvalue.split(':')[0];
    if(document.getElementById("inputTool0Style")) document.getElementById("inputTool0Style").value=keyvalue
    delete    cssAttributes[key];

    updateStructure({ cssAttributes}, idObj);
  };


  const addMixValue=(mixvalue) => {
    hoverEnd(mixvalue)
setFilter()
var inputObj= document.getElementById("inputTool0Style")
if(inputObj) inputObj.value=""
var parts = mixvalue.split(":");
if(  isMediaCSS(mixvalue))   addMediaClasse(mixvalue);

else  if(isAttributeCSS(parts[0].trim())   && parts.length>1) addCssAttribute(mixvalue)
  else  addClasse(mixvalue);
  
}
  const addCssAttribute = (keyvalue) => {

    var cssAttributes = getStructure(structure, idObj).cssAttributes?? {};
    var [key,value] = keyvalue.split(':');
     cssAttributes[key]=value ;

    updateStructure({ cssAttributes}, idObj);
  };

  function isMediaCSS(filter) {
    // Créer un élément div factice

  
    // Vérifier si l'attribut CSS est défini pour l'élément div
    
    return    !(filter+"").startsWith("(")   &&   (filter+"").split(":").length>2;
  }
function isAttributeCSS(prop) {
  // Créer un élément div factice
  var div = document.getElementById('root');

  // Vérifier si l'attribut CSS est défini pour l'élément div
  
  return prop in div.style;
}

  const [filter, setFilter] = useState();

  const removeClasse = (classe) => {
    var _classes = classes.filter((_classe) => _classe !== classe);
if(document.getElementById("inputTool0Style")) document.getElementById("inputTool0Style").value=classe
    updateStructure({ className: _classes.join(" ") }, idObj);
  };
  const addMediaClasse = (classe) => {
   
  var  _mediaClasses = [...mediaClasses,classe]


    updateStructure({ mediaClasses:_mediaClasses}, idObj);
  };

  const removeMediaClasse = (classe) => {
    var _mediaClasses = mediaClasses.filter((_classe) => _classe !== classe);
if(document.getElementById("inputTool0Style")) document.getElementById("inputTool0Style").value=classe
    updateStructure({ mediaClasses: _mediaClasses }, idObj);
  };

  const addClasse = (classe) => {
    var _classes = classes;

    _classes.push(classe);

    updateStructure({ className: _classes.join(" ") }, idObj);
  };

  var oldvalue =null
  const hoverStart = (mixvalue) => {

    try{
    mixvalue=mixvalue.trim()
    var obj = document.getElementById(idObj);
    if (!obj) return
    console.log("hoverStart", obj, mixvalue);


var parts = mixvalue.split(":");
if(parts.length>2) return
if( parts.length===1 ||  !isAttributeCSS( parts[0]))
 obj.classList.add(mixvalue);
     else {
      oldvalue =  obj.style[parts[0]]
      obj.style[parts[0]]=parts[1];
     }
    }
    catch(err)
    {
      window.alert("erreur de style")}
  };

  const hoverEnd = (mixvalue) => {
    try{
    mixvalue=mixvalue.trim()
    var obj = document.getElementById(idObj);
    if (!obj) return
    var parts = mixvalue.split(":");
    if(parts.length>2) return
    console.log("hoverEnd", obj, mixvalue);
    if( parts.length===1 ||  !isAttributeCSS(   parts[0]))  
    obj.classList.remove(mixvalue);
  else  {
    obj.style[parts[0]]=oldvalue
  }
}
catch(err)
{
  window.alert("erreur de style")}
  };

  return (
    <>
      {show && (
        <div
          className={
            "bg-white w-full h-full justify-between flex flex-col relative  mr-14"
          }
        >
          <div className="w-full flex-nowrap text-start ">
            {" "}
            {classes &&
              classes.map((classe, i) => (
                <div
                  key={i}
                  onClick={() => removeClasse(classe)}
                  className="appbutton  inline-block text-xs p-1 px-2 m-1 mb-1 text-white rounded-full bg-green-500 border-2"
                >
                  <FontAwesomeIcon icon={fa.faCheck} className="hidden" />
                  {" " + classe}
                </div>
              ))}
               {mediaClasses &&
              mediaClasses.map((classe, i) => (
                <div
                  key={i}
                  onClick={() => removeMediaClasse(classe)}
                  className="appbutton  inline-block text-xs p-1 px-2 m-1 mb-1 text-white rounded-full bg-orange-500 border-2"
                >
                  <FontAwesomeIcon icon={fa.faCheck} className="hidden" />
                  {" " + classe}
                </div>
              ))}          
            {cssAttributes &&
              cssAttributes.map((mixvalue, i) => (
                <div
                  key={i}
                  onClick={() => removeCssAttribute(mixvalue)}
                  className="appbutton  inline-block text-xs p-1 px-2 m-1 mb-1 text-white rounded-full bg-blue-500 border-2 "
                >
                  <FontAwesomeIcon icon={fa.faCheck} className="hidden" />
                  {" " + mixvalue}
                </div>
              ))}
          </div>

 

          <div className="flex flex-row  w-full p-1">

            <InputTextArea
            ref= {textStyle}
              
              id={"inputTool0Style"}
              onChange={(value) => setFilter(value)}
              className="   grow rounded-lg text border border-slate-300 text-xs p-1 bg-white text-black"
              placeholder="Saisir classe tw ou attribut css"
            />
            {filter &&
              filter.length > 2  && (
                <><FontAwesomeIcon
                  icon={fa.faPlus}
                  className={"-ml-6  text-white p-2 appbutton rounded-full " + (isMediaCSS( filter) ? "bg-orange-500" :( 
                    filter.split(":").length===1 || 
                    
                    !isAttributeCSS(  filter.split(":")[0])  
                    ?"bg-green-500":"bg-blue-500"))}
                  onClick={() => addMixValue(filter)}
                  onMouseOver={() => hoverStart(filter)}
                  onMouseOut={() => hoverEnd(filter)}
                />
                {!isMediaCSS( filter) &&  !isAttributeCSS(  filter.split(":")[0]) && 
                <FontAwesomeIcon
                  icon={fa.faRecycle}
                  className={"absolute  bottom-0   right-0 text-white p-2 appbutton rounded-full bg-green-500" }
                  onClick={() => getTailwindStyles(filter)}
             
                />}
                </>
              )}
          </div>
        </div>
      )}
      <div
        className={
          " hidden  appbutton border-2  border-white  p-2  m-2 text-xs" +
          (show
            ? " bg-white text-black bg-opacity-80 "
            : " bg-black text-white bg-opacity-30 ")
        }
        onClick={() => setShow(!show)}
      >
        CSS...
      </div>
    </>
  );
};
export default Tool0_Style;
